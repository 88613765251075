import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { format, parse } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { IFeedback } from '../models/feedback.model';
import { IGoal } from '../models/goal.model';
import { IProductIdea } from '../models/productIdea.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public ideas$ = new BehaviorSubject<IProductIdea[]>([]);
  public feedbacks$ = new BehaviorSubject<IFeedback[]>([]);
  public companyGoals$ = new BehaviorSubject<IGoal[]>([]);
  public isPhoneSize$ = new BehaviorSubject<boolean>(false);
  public userCoinsChanged$ = new BehaviorSubject<boolean>(false);
  private amountRequestChanged$ = new BehaviorSubject<boolean>(false);
  public updateQuarterSums = new Subject<void>();

  public datePickerOptions: any = {
    dateFormat: 'd.m.Y',
    altInput: true,
    altFormat: 'd.m.Y',
  };

  constructor() {
    this.checkWindowSize();
    window.addEventListener('resize', () => {
      this.checkWindowSize();
    });
  }

  /**
   * BehaviorSubject to hold the visibility state of the sidebar.
   * The '$' symbol conventionally indicates that this variable is an Observable.
   */
  private _showSidebar$ = new BehaviorSubject<boolean>(true);

  get showSidebar$() {
    return this._showSidebar$;
  }

  // Formats date to ISO String
  public dateToISOString(event: Event): string {
    const dateString = (event.target as HTMLInputElement).value;
    const parsedDate = parse(dateString, 'dd.MM.yyyy', new Date());
    const utcDate = new Date(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()));
    return format(utcDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
  }

  public setSidebarVisibility(isVisible: boolean): void {
    this._showSidebar$.next(isVisible);
  }

  public setFeedbacks(feedbacks: IFeedback[]): void {
    this.feedbacks$.next(feedbacks);
  }

  public setCompanyGoals(companyGoals: IGoal[]): void {
    this.companyGoals$.next(companyGoals);
  }

  public setIdeas(ideas: IProductIdea[]): void {
    this.ideas$.next(ideas);
  }

  public setAmountRequestForAdminBadgeChanged(changed: boolean): void {
    this.amountRequestChanged$.next(changed);
  }

  public getAmountRequestedForAdminBadge(): BehaviorSubject<boolean> {
    return this.amountRequestChanged$;
  }

  public sumToFixed(value: number, type: string): string {
    if (value > 99000 && type === 'chart') {
      const formattedNumber = value / 1000000;
      return formattedNumber.toFixed(2) + 'M';
    } else {
      const formattedNumber = value / 1000;
      return formattedNumber.toFixed(1) + 'k';
    }
  }

  public checkWindowSize(): void {
    if (window.innerWidth < 1024) {
      this.isPhoneSize$.next(true);
    } else {
      this.isPhoneSize$.next(false);
    }
  }

  public calculateMinutes(time: string): number {
    let totalMinutes = 0;
    if (!time) return 0;

    if (time.includes(':')) {
      const [hoursStr, minutesStr] = time.split(':');

      let hours = parseInt(hoursStr, 10);
      let minutes = parseInt(minutesStr, 10);
      if (minutes < 10 && minutesStr.length === 1) minutes *= 10;
      const isNegative = hoursStr.startsWith('-');
      if (isNegative) {
        hours = Math.abs(hours);
      }

      totalMinutes = (isNegative ? -1 : 1) * (hours * 60 + minutes);
    } else {
      totalMinutes = parseInt(time, 10) * 60;
    }

    return totalMinutes;
  }

  public formatMinutes(minutes: number): string {
    const isNegative = minutes < 0;
    minutes = Math.abs(minutes);

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

    let formattedTime = `${formattedHours}:${formattedMinutes}`;

    if (isNegative) {
      formattedTime = '-' + formattedTime;
    }
    return formattedTime;
  }

  public onTimeInput(event: Event, timeForm: FormGroup, allowHyphen = false): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    if (allowHyphen) {
      value = value.replace(/(?!^-)[^\d:]/g, '');
    } else {
      value = value.replace(/[^\d:]/g, '');
    }

    if (
      !value.includes(':') &&
      ((value.includes('-') && value.length > 3) || (!value.includes('-') && value.length > 2))
    ) {
      value = value.replace(/(\d{2})(?=\d)/, '$1:');
    }

    if (value.includes(':')) {
      const [hours, minutes] = value.split(':');
      const validMinutes = minutes ? minutes.substring(0, 2) : '';
      const correctedMinutes = +validMinutes > 59 ? '59' : validMinutes;

      value = `${hours}:${correctedMinutes}`;
    }

    timeForm.patchValue({ timeInput: value });
  }

  public formatTimeInput(inputValue: string): string {
    if (inputValue.includes(':')) {
      const maxLength = inputValue.startsWith('-') ? 6 : 5;
      if (inputValue.length > maxLength) {
        inputValue = inputValue.substring(0, maxLength);
      }
    }

    if (inputValue.at(-1) === ':') {
      inputValue = inputValue.slice(0, -1);
    }

    return inputValue;
  }
}
