import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { BookCategory, Type } from 'src/app/enum/book.enum';
import { IBook, IBookDialog } from 'src/app/models/book.model';
import { IConfirmDataBook } from 'src/app/models/confirm-data';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-manage-library-dialog',
  templateUrl: './manage-library-dialog.component.html',
  styleUrl: './manage-library-dialog.component.scss',
})
export class ManageLibraryDialogComponent implements OnInit {
  @Input() public dialogData: IConfirmDataBook;
  @Input() public booksDataEdit = {} as IBook;

  @Output() public closeDialog = new EventEmitter<boolean>();
  @Output() public createNewBook = new EventEmitter<IBookDialog>();
  @Output() public updateBook = new EventEmitter<any>();
  public Type = Type;

  public booksData: any = {} as IBookDialog;
  public selectedCategory = BookCategory.Misc;
  public selectedType = Type.book;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    if (this.dialogData.edit) {
      this.booksData = { ...this.booksDataEdit } as IBookDialog;
      this.selectedCategory = this.booksDataEdit.category;
      this.selectedType = this.booksDataEdit.type;
      this.booksData.arrayType = this.dialogData.arrayType;
    } else {
      this.selectedCategory = BookCategory.Misc;
      this.selectedType = Type.book;
      this.booksData.category = BookCategory.Misc;
      this.booksData.type = Type.book;
      this.booksData.arrayType = this.dialogData.arrayType;
    }
  }

  public createNewBookCard(): void {
    if (!this.allFieldsFilled()) {
      this.toastr.error('All fields are required!');
      return;
    }

    this.booksData.ownerId = this.authService.getCurrentEmployee()._id;
    this.booksData.currentHolderId = this.booksData.ownerId;
    if (this.dialogData.arrayType === 'suggestion') {
      this.booksData.suggestedBy = this.booksData.ownerId;
    }
    this.createNewBook.emit(this.booksData);
  }

  public getBookCategories(): string[] {
    return Object.values(BookCategory);
  }
  public getBookTypes(): string[] {
    return Object.values(Type);
  }
  public onSelectCategoryClick(event: Event): void {
    const category = (event.target as HTMLSelectElement).value;
    this.booksData.category = category;
  }
  public onSelectTypeClick(event: Event): void {
    const type = (event.target as HTMLSelectElement).value;
    this.booksData.type = type;
    this.selectedType = type as Type;
  }

  public editBookCard(): ActiveToast<any> | void {
    if (this.allFieldsFilled()) {
      this.updateBook.emit(this.booksData);
    } else {
      return this.toastr.error('All fields are required!');
    }
  }

  private allFieldsFilled() {
    if (this.selectedType === Type.eBook) {
      return this.booksData.title && this.booksData.author && this.booksData.url;
    } else {
      return this.booksData.title && this.booksData.author;
    }
  }

}
