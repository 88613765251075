<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl flex-col gap-5">
    <div class="flex w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box [headline]="'Submit Feedback'" [headerIcon]="'📓'"></app-text-content-box>
      <div class="flex flex-col items-center">
        <div class="relative flex w-full flex-col gap-5 lg:items-end">
          <textarea
            [(ngModel)]="newFeedback"
            class="w-full resize-none border-2 border-sunflowerGold bg-paleOrange p-5"
            data-cy="feedback-text-area"
          ></textarea>
          <div class="flex w-full flex-wrap items-center gap-4 max-lg:justify-center">
            <app-badge
              (feedbackOptionSelected)="onBadgeClick($event)"
              [selectedCategory]="selectedFeedbackCategory"
              [feedbackCategory]="FeedbackCategory.SAVING"
              data-cy="fb-category-saving"
            ></app-badge>
            <app-badge
              (feedbackOptionSelected)="onBadgeClick($event)"
              [selectedCategory]="this.selectedFeedbackCategory"
              [feedbackCategory]="FeedbackCategory.IMPROVEMENT"
              data-cy="fb-category-improvement"
            ></app-badge>
            <app-badge
              (feedbackOptionSelected)="onBadgeClick($event)"
              [selectedCategory]="selectedFeedbackCategory"
              [feedbackCategory]="FeedbackCategory.FEEDBACK"
              data-cy="fb-category-feedback"
            ></app-badge>
            <app-badge
              (feedbackOptionSelected)="onBadgeClick($event)"
              [selectedCategory]="selectedFeedbackCategory"
              [feedbackCategory]="FeedbackCategory.TOOLS"
              data-cy="fb-category-tools"
            ></app-badge>
            <app-badge
              (feedbackOptionSelected)="onBadgeClick($event)"
              [selectedCategory]="selectedFeedbackCategory"
              [feedbackCategory]="FeedbackCategory.OTHER"
              data-cy="fb-category-other"
            ></app-badge>
            <button
              (click)="handleCreateFeedback()"
              class="right-0 flex h-11 items-center justify-center gap-2 border border-black bg-almostBlack p-5 text-xs text-sunflowerGold lg:absolute"
              data-cy="submitFeedbackButton"
            >
              Submit Feedback
              <img class="h-4" src="../../../assets/img/icons/feedback.png" alt="feedback" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5 flex flex-col bg-lightGrayish p-5" data-cy="feedback-container">
      <app-text-content-box [headline]="'All Entries'" [headerIcon]="'📓'"></app-text-content-box>
      <app-feedbacks class="overflow-auto pr-1"></app-feedbacks>
    </div>
  </div>
</div>
