<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl gap-5 max-lg:flex-col">
    <div class="flex flex-1 flex-col gap-4 bg-lightGrayish p-5 lg:mb-5 lg:gap-5">
      <app-text-content-box [headline]="'42 Shop'" [headerIcon]="'🛍️'" class="lg:pb-2"></app-text-content-box>
      <app-article-card [products]="products" (articleBought)="getLatestTransactions()"></app-article-card>
    </div>

    <div class="mb-5 flex h-full w-96 flex-col gap-4 bg-lightGrayish p-5 max-lg:w-full lg:gap-5">
      <app-text-content-box [headline]="'Payment History'" [headerIcon]="'⌛'" class="lg:pb-2"></app-text-content-box>
      <div class="flex max-h-96 flex-col gap-3 overflow-y-auto">
        @for (history of paymentHistory; track history) {
          <div class="flex text-sm font-medium max-lg:text-xs">
            <span class="w-20 border-r-2 border-veryFaintBlack text-veryFaintBlack">{{
              history.transactionTime | date: 'dd.MM.yyyy'
            }}</span>
            <div class="overflow-hidden text-ellipsis whitespace-nowrap pl-4">
              <span [title]="history.product.title" class="truncate">{{ history.product.title }}</span>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
