<div
  class="min-w-screen animated fadeIn faster fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat outline-none focus:outline-none"
  id="modal-id"
>
  <div class="absolute inset-0 z-40 bg-black opacity-80"></div>
  <form class="z-40">
    <div class="flex h-screen items-center justify-center">
      <div
        class="flex w-96 min-w-fit flex-col items-center justify-center rounded-[4px] border bg-lightGrayish px-6 py-14 shadow-md max-lg:h-full max-lg:w-screen max-lg:rounded-none"
      >
        <div class="mb-8 flex justify-center">
          <img class="w-24" src="assets/img/42.png" alt="" />
        </div>
        <div class="flex w-full flex-col rounded-md text-sm font-medium text-black">
          <label class="mb-2">Old Password</label>
          <input
            [(ngModel)]="oldPassword"
            name="oldPassword"
            class="mb-6 border p-3 text-black hover:border-yellow-500 hover:outline-none focus:outline-none"
            type="password"
            placeholder="Old password"
            required
          />
          <label class="mb-2">New Password</label>
          <input
            [(ngModel)]="newPassword"
            name="newPassword"
            class="mb-6 border p-3 text-black hover:border-yellow-500 hover:outline-none focus:outline-none"
            type="password"
            placeholder="New Password"
            required
            minlength="6"
          />
          <label class="mb-2">Confirm New Password</label>
          <input
            [(ngModel)]="confirmPassword"
            name="confirmPassword"
            class="border p-3 text-black hover:border-yellow-500 hover:outline-none focus:outline-none"
            type="password"
            placeholder="Confirm Password"
            required
            minlength="6"
          />
          <div class="h-6">
            @if (newPassword.length < 6 && newPassword) {
              <span class="mb-5 text-red-400">Please enter a valid password</span>
            }
          </div>
        </div>
        <div class="flex flex-col items-center gap-5">
          <button
            class="flex h-10 items-center justify-center border border-black bg-almostBlack px-5 py-2.5 text-center text-sm font-medium tracking-wider text-sunflowerGold shadow-sm hover:bg-black hover:shadow-lg focus:outline-none focus:ring-4 sm:w-auto md:mb-0"
            (click)="changePassword()"
          >
            Change Password
          </button>
          @if (!employee.firstLogin) {
            <button
              class="mb-2 flex h-10 w-full items-center justify-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
              (click)="closeDialog()"
            >
              Close
            </button>
          }
        </div>
      </div>
    </div>
  </form>
</div>
