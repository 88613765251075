import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IEmployee } from '../models/employee.model';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private http: HttpClient) {}

  public getCoinBalanceHistoryOfEmployee(employee: IEmployee): Observable<any> {
    return this.http.get(environment.baseUrl + `/employee/coins/history/${employee._id}`);
  }

  public getOvertimeHistoryOfEmployee(employee: IEmployee): Observable<any> {
    return this.http.get(environment.baseUrl + `/employe/overtime-history/${employee._id}`);
  }
}
