<app-sorting-segment (sortOptionSelected)="sortOptionSelected($event)"></app-sorting-segment>

@for (feedback of feedbacks; track feedback; let i = $index) {
<div class="flex flex-col">
  @if (!feedback.isSolved) {
  <div class="flex flex-col items-center w-full center pt-5 mb-1 first:pt-2">
    @if (isShowSolveButton()) {
    <button (click)="createRemoveModal(feedback)" class="flex w-10 z-10 -mb-5">
      <img src="../../../assets/img/icons/solve.png" alt="solve" />
    </button>
    }
    <div
      class="flex flex-col items-center w-full relative p-3 max-lg:pt-5 lg:p-5 border-2 max-lg:text-xs bg-paleOrange border-sunflowerGold"
      data-cy="feedback-item"
    >
      {{ feedback.text }}
      <div class="flex mt-5 gap-1 max-sm:flex-col-reverse w-full items-center justify-center max-sm:relative">
        <app-badge
          [dataCategory]="feedback.category"
          [isHomePage]="isHomePage"
          class="sm:absolute left-4 max-sm:left-2 bg-{{ feedback.color }}-500 flex items-center h-[30px] rounded white"
          data-cy="feedback-badge"
        ></app-badge>
        <div class="flex items-center">
          <div class="flex gap-2 items-center">
            <button (click)="voteFeedback(feedback, 'down')" data-cy="fb-arrow-down">
              <img class="h-7 rotate-180" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
            </button>

            <p class="lg:text-2xl font-bold" data-cy="feedback-vote-count">{{ feedback.voteCount }}</p>
            <button (click)="voteFeedback(feedback, 'up')" data-cy="fb-arrow-up">
              <img class="h-7" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  } @if (confirmDialog) {
  <app-confirm-dialog
    (closeDialog)="confirmDialog = false"
    (saveChanges)="resolveFeedback(currentFeedback)"
    [data]="modalData"
  ></app-confirm-dialog>
  }
</div>
}
