import { IEmployee } from './employee.model';

export interface ITransaction {
  _id?: string;
  employee: IEmployee;
  transactionTime: Date;
  product: IProduct;
  state: EState;
  size: string;
  decisionMaker?: string;
}

export interface IProduct {
  title: string;
  price: string;
}

export enum EState {
  REQUESTED,
  APPROVED,
  CANCELED,
}
