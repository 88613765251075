import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfirmData } from 'src/app/models/confirm-data';
import { ILeave } from 'src/app/models/leave.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() data: IConfirmData;

  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() saveChanges = new EventEmitter<IConfirmData>();
  @Output() deleteLeave = new EventEmitter<ILeave>();

  public onSubmit(isBought?: 'true' | 'false'): void {
    if (this.data.leave) this.deleteLeave.emit(this.data.leave);
    if (isBought) {
      this.data.isBought = isBought;
    }
    this.saveChanges.emit(this.data);
    this.closeDialog.emit(true);
  }

  public onClose(): void {
    this.closeDialog.emit(true);
  }
}
