<div class="relative">
  <div
    (click)="onToggleDropDown()"
    class="text-mainGreen flex h-12 min-w-[10rem] cursor-pointer items-center justify-between border border-gray-300 bg-white px-3 py-2"
    data-cy="ct-dropdown"
    [ngClass]="{ 'border-b-0': showDropdown, 'min-w-[14rem]': isEmployeeDropdown }"
  >
    <span class="truncate pr-2">{{ placeholder }}</span>
    <fa-icon [icon]="dropDownIcon" class="-mb-1" [ngClass]="{ '-mb-0 rotate-180': showDropdown }"> </fa-icon>
  </div>
  @if (showDropdown) {
    <ul
      class="absolute z-50 flex w-full flex-col gap-2 border border-t-0 border-gray-300 bg-white p-2 text-veryFaintBlack transition-all duration-500"
    >
      @for (element of elements; track element) {
        <li
          (click)="onSelectElement(element)"
          class="flex cursor-pointer items-center justify-between whitespace-nowrap px-1 hover:text-[#E6AE48]"
          data-cy="ct-option"
        >
          @if (isEmployeeDropdown) {
            <span class="overflow-hidden text-ellipsis whitespace-nowrap"
              >{{ element.firstname }} {{ element.lastname }}</span
            >
          }

          @if (!isEmployeeDropdown && !isRevenueDropdown) {
            <span>
              {{ element }}
            </span>
          }
          @if (isRevenueDropdown) {
            <span>
              {{ element.value }}
            </span>
          }
        </li>
      }
    </ul>
  }
</div>
