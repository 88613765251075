import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { RevenuesResponse } from 'src/app/models/response.model';
import { IRevenuesQuarters } from 'src/app/models/revenue.model';
import { HelperService } from 'src/app/services/helper.service';
import { RevenueService } from 'src/app/services/revenue.service';

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss'],
})
export class RevenueComponent {
  private subscription = new Subscription();

  public progressPercentage = '0';

  public quarters: IRevenuesQuarters = [
    { label: 'Q1', realSum: 0, goalSum: 0 },
    { label: 'Q2', realSum: 0, goalSum: 0 },
    { label: 'Q3', realSum: 0, goalSum: 0 },
    { label: 'Q4', realSum: 0, goalSum: 0 },
  ];

  constructor(
    private revenueService: RevenueService,
    private toastr: ToastrService,
    public helperService: HelperService,
  ) {
    this.updateQuarterSums();
    this.getRealsAndGoalsRevenuesByQuarter();
  }

  updateQuarterSums(): void {
    this.helperService.updateQuarterSums.subscribe(() => {
      this.getRealsAndGoalsRevenuesByQuarter();
    });
  }

  getRealsAndGoalsRevenuesByQuarter(): void {
    this.subscription.add(
      this.revenueService.realsAndGoalsRevenuesByQaurter().subscribe({
        next: (response: RevenuesResponse) => {
          this.setQuarters(response);
          this.setPercentageForProgressbar();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  setQuarters(response: RevenuesResponse) {
    this.quarters.forEach((quarter, i) => {
      quarter.realSum = response[i + 1].real;
      quarter.goalSum = response[i + 1].goalBest;
    });
  }

  setPercentageForProgressbar(): void {
    const getPercentage = (this.quarters[3].realSum / this.quarters[3].goalSum) * 100;
    const percentageString = getPercentage.toString();
    this.progressPercentage = percentageString.split('.')[0];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
