import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeedbackCategory } from 'src/app/models/feedback.model';
import { faTag } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
})
export class BadgeComponent {
  @Output() feedbackOptionSelected = new EventEmitter<FeedbackCategory>();
  @Input() feedbackCategory: FeedbackCategory;
  @Input() selectedCategory: string;
  @Input() dataCategory?: string;
  @Input() isHomePage: boolean;

  public FeedbackCategory = FeedbackCategory;
  public readableCategory: string;
  public feedbackCategoryColor: string;
  public badgeIcon = faTag;

  ngOnInit() {
    if (this.dataCategory) {
      this.readableCategory = this.dataCategory;
    } else {
      this.readableCategory = this.getReadableCategory(this.feedbackCategory);
    }
  }

  onButtonClick(category: FeedbackCategory) {
    if (!this.dataCategory) {
      this.feedbackOptionSelected.emit(category);
    }
  }

  getReadableCategory(category: FeedbackCategory): string {
    switch (category) {
      case FeedbackCategory.SAVING:
        return 'Saving';
      case FeedbackCategory.IMPROVEMENT:
        return 'Improvement';
      case FeedbackCategory.FEEDBACK:
        return 'Feedback';
      case FeedbackCategory.TOOLS:
        return 'Tools';
      case FeedbackCategory.OTHER:
        return 'Other';
    }
  }
}
