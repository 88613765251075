import { Pipe, PipeTransform } from '@angular/core';
import { IBook } from '../models/book.model';

@Pipe({
  name: 'filterByCategory',
})
export class FilterByCategoryPipe implements PipeTransform {
  transform(books: IBook[], category: string): IBook[] {
    if (category === 'All Categories' || category === 'Category') {
      return books;
    }
    return books.filter((book) => book.category === category);
  }
}
