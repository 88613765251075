import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IEmployee } from '../models/employee.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _loggedIn = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  /**
   * Logges in employee and stores data in local storage
   * @param {string} email - Email of employee.
   * @param {string} password - Password of employee.
   */
  public login(email: string, password: string) {
    return this.http
      .post(environment.baseUrl + '/auth/login', {
        email: email,
        password: password,
      })
      .pipe(
        tap({
          next: (res: any) => {
            this.setToken('access_token', res['DATA'].accessToken);
            this.setToken('refresh_token', res['DATA'].refreshToken);
            this.setCurrentEmployee(res['DATA'].employee);
            this._loggedIn.next(true);
          },
          error: (err: any) => throwError(() => new Error(err)),
        }),
      );
  }

  public isLoggedIn() {
    return this._loggedIn.asObservable();
  }

  // Logges out employee and removes data from local storage
  public logout(): void {
    localStorage.clear();
    this._loggedIn.next(null);
    this.router.navigateByUrl('/login');
    this.toastr.success('Logged out successfully');
  }

  public setCurrentEmployee(employee: IEmployee) {
    localStorage.setItem('employee', JSON.stringify(employee));
  }

  /* Sets new token */
  private setToken(type: string, token: string) {
    localStorage.setItem(type, token);
  }

  /* Gets stored authToken for future requests */
  public getAccessToken() {
    return localStorage.getItem('access_token');
  }

  /* Gets stored authToken for future requests */
  public getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  /**
   * Refreshes token after old one expires */
  public refreshToken(): Observable<any> {
    const body = {
      employee: this.getCurrentEmployee(),
      refreshToken: this.getRefreshToken(),
    };
    return this.http.post(`${environment.baseUrl}/auth/refreshToken`, body).pipe(
      tap((res: any) => {
        this.setToken('access_token', res.DATA.accessToken);
        this.setToken('refresh_token', res.DATA.refreshToken);
      }),
    );
  }

  public getCurrentEmployee(): IEmployee {
    const employee = localStorage.getItem('employee');
    return employee ? JSON.parse(employee) : null;
  }

  /**
   * Changes password of user
   * @param {string} oldPassword - Current password of employee.
   * @param {string} newPassword - New password of employee.
   */
  public changePassword(oldPassword: string, newPassword: string) {
    const employeeID = this.getCurrentEmployee()._id;
    return this.http.put(environment.baseUrl + `/employee/changePassword/${employeeID}`, {
      id: employeeID,
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  public setFirstLoginStatus(employee: IEmployee) {
    return this.http.put(environment.baseUrl + `/employee/${employee._id}`, {
      firstname: employee.firstname,
      email: employee.email,
      isAdmin: employee.isAdmin,
      firstLogin: false,
    });
  }
}
