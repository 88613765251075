<div class="flex bg-background -mt-10">
  <div class="flex w-[70vw] justify-center items-center max-lg:w-full">
    <form (ngSubmit)="loginUser()" [formGroup]="loginForm">
      <div class="flex items-center justify-center h-screen pt-28 xsC:pt-0">
        <!-- Login Container -->
        <div class="min-w-fit flex-col border bg-white px-6 py-14 shadow-md rounded-[4px] w-96 max-lg:w-72 max-lg:h-96">
          <div class="mb-8 flex justify-center">
            <img class="w-24" src="assets/img/42.png" alt="42 Logo" />
          </div>
          <div class="flex flex-col text-sm rounded-md">
            <input
              formControlName="email"
              class="rounded-[4px] border p-3 hover:outline-none focus:outline-none hover:border-yellow-500"
              data-cy="login-email"
              type="text"
              placeholder="Username or Email id"
            />
            <div class="h-5">
              @if (email && email.invalid && email.touched) {
              <span class="text-red-400 mb-5">This email is not valid</span>
              }
            </div>

            <input
              formControlName="password"
              class="border rounded-[4px] p-3 hover:outline-none focus:outline-none hover:border-yellow-500"
              data-cy="login-password"
              type="password"
              placeholder="Password"
            />
            <div class="h-8">
              @if (password && password.invalid && password.touched) {
              <span class="text-red-400 mb-5">Please enter a password</span>
              }
            </div>
          </div>

          <button
            [disabled]="loginForm.invalid"
            class="w-full border p-2 bg-gradient-to-r from-gray-800 bg-gray-500 text-white rounded-[4px] hover:bg-slate-400"
            data-cy="login-submit"
            type="submit"
          >
            Sign in
          </button>
        </div>
      </div>
    </form>
  </div>

  <div
    class="flex-col flex justify-center items-center h-screen w-[30vw] gap-4 max-lg:gap-0 shadow-lg bg-gray-200 max-lg:absolute max-lg:w-full max-lg:top-0 max-lg:h-auto"
  >
    <div class="w-4/5">
      <div class="max-lg:text-sm max-sm:text-xs pb-0 xl:pb-8 lg:pb-4">
        <h1 class="mb-4 max-lg:mb-1 text-2xl max-lg:text-base font-bold">
          🔮 Unsere <span class="underline">Vision</span>
        </h1>

        Jeden Tag machen wir die Welt etwas digitaler, eröffnen neue Möglichkeiten und vereinfachen das Leben sowie den
        Alltag der Menschen.
      </div>
      <div class="flex pt-24 max-lg:pt-1 justify-around">
        <a href="https://app.clockify.me" target="_blank">
          <img
            [routerLink]="['/my-book-listing']"
            class="w-5 lg:w-[4.2rem] m-2 cursor-pointer -mt-[0.01rem]"
            src="assets/img/clockify.png"
            alt="clockify"
          />
        </a>
        <a href="https://app.clickup.com" target="_blank">
          <img class="w-5 lg:w-16 m-2 cursor-pointer" src="assets/img/clickup.png" alt="clickup" />
        </a>
        <a href="https://www.office.com/" target="_blank">
          <img class="w-6 lg:w-[4.75rem] m-2 cursor-pointer" src="assets/img/office.png" alt="office" />
        </a>
      </div>
    </div>
  </div>
</div>
