export enum EClothingSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
}

export enum EShoeSize {
  Size35 = '35',
  Size36 = '36',
  Size37 = '37',
  Size38 = '38',
  Size39 = '39',
  Size40 = '40',
  Size41 = '41',
  Size42 = '42',
  Size43 = '43',
  Size44 = '44',
  Size45 = '45',
  Size46 = '46',
  Size47 = '47',
}
