import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IConfirmData } from 'src/app/models/confirm-data';
import { IEmployee } from 'src/app/models/employee.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-edit-overtime',
  templateUrl: './edit-overtime.component.html',
  styleUrls: ['./edit-overtime.component.scss'],
})
export class EditOvertimeComponent implements OnChanges {
  @ViewChild('employeeSelect') employeeSelect: ElementRef<HTMLSelectElement>;
  @Input() employees: IEmployee[];
  @Output() employeeUpdate = new EventEmitter<IEmployee>();

  public timeForm: FormGroup;

  public confirmDialog: boolean = false;
  public dataToConfirm = {} as IConfirmData;

  public employeeID: string;
  public reason: string;

  public subscription = new Subscription();

  constructor(
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    public helperService: HelperService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.timeForm = this.fb.group({
      timeInput: ['', [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3])([-:][0-5][0-9])?$/)]],
    });
  }

  public onSelectEmployee(employee: IEmployee): void {
    this.employeeID = employee._id;
  }

  private filterActiveEmployees(): void {
    this.employees = this.employees?.filter((employee) => !employee.isArchived);
  }

  /**
   * Opens confirm dialog and passes relevant data.
   * @param {string} type - Date that is clicked.
   */
  public openConfirmDialog(type: string): void {
    const overtime = this.timeForm.get('timeInput').value;
    const formattedOvertime = this.helperService.formatTimeInput(overtime);
    this.timeForm.patchValue({ timeInput: formattedOvertime });

    if (this.employeeID && this.reason && this.timeForm.get('timeInput').value) {
      const employee = this.employees.find((e) => e._id === this.employeeID);
      if (type == 'editOvertime') {
        this.setConfirmDialog(type, employee);
      }
      this.confirmDialog = true;
    } else this.toastr.error('Please fill all required fields first!');
  }

  setConfirmDialog(type: string, employee: IEmployee): void {
    this.dataToConfirm.headline = 'Are you sure ?';
    this.dataToConfirm.text = `Do you really want to edit overtime hours of ${employee.firstname}?`;
    this.dataToConfirm.image = 'overtime';
    this.dataToConfirm.type = type;
  }

  /**
   * Updates overtime of specific emplyoee
   */
  public updateOvertime(): void {
    const overtime = this.timeForm.get('timeInput').value;
    const overtimeInMinutes = this.helperService.calculateMinutes(overtime);

    this.subscription.add(
      this.employeeService.updateOvertime(this.employeeID, this.reason, overtimeInMinutes).subscribe({
        next: () => {
          this.toastr.success('Overtime was updated successfully');
          this.reason = '';

          this.timeForm.patchValue({ timeInput: '' });

          this.updateEmployee(overtimeInMinutes);
        },
        error: (err: any) => {
          console.warn(err);
        },
      }),
    );
  }

  private updateEmployee(overtimeInMinutes: number): void {
    const employee = this.employees.find((e) => e._id === this.employeeID);
    employee.overtimeBalance += overtimeInMinutes;
    this.employeeUpdate.emit(employee);
    this.employeeSelect.nativeElement.value = '';
    this.employeeID = '';
  }

  public closeConfirmDialog(): void {
    this.confirmDialog = false;
  }

  public ngOnChanges(): void {
    this.filterActiveEmployees();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
