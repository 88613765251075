import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements OnInit {
  @Input() elements: any[];
  @Input() placeholder: string;
  @Input() isEmployeeDropdown = false;
  @Input() isRevenueDropdown = false;
  @Input() selectedElement: any;

  @Output() selectElement = new EventEmitter<any>();

  public showDropdown = false;
  public dropDownIcon = faAngleDown;

  ngOnInit() {
    if (this.selectedElement) {
      this.setPlaceholder(this.selectedElement);
    }
  }

  public onSelectElement(element) {
    this.showDropdown = false;
    this.setPlaceholder(element);
    this.selectElement.emit(element);
  }

  public setPlaceholder(element) {
    this.placeholder = this.isEmployeeDropdown
      ? element.firstname + ' ' + element.lastname
      : this.isRevenueDropdown
        ? element.value
        : element;
  }

  public onToggleDropDown(): void {
    this.showDropdown = !this.showDropdown;
  }
}
