import { Component, EventEmitter, Output } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IEmployee } from 'src/app/models/employee.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-change-pw',
  templateUrl: './change-pw.component.html',
  styleUrls: ['./change-pw.component.scss'],
})
export class ChangePwComponent {
  @Output() closePasswordDialog = new EventEmitter<boolean>();

  public oldPassword: string = '';
  public newPassword: string = '';
  public confirmPassword: string = '';
  private subscription = new Subscription();
  public employee: IEmployee;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
  ) {
    this.employee = this.authService.getCurrentEmployee();
  }

  // Changes password of employee.
  public changePassword(): void | ActiveToast<string> {
    if (!this.oldPassword || this.newPassword.length < 6 || this.newPassword !== this.confirmPassword) {
      return this.toastr.error('Failed to change password');
    }
    this.subscription.add(
      this.authService.changePassword(this.oldPassword, this.newPassword).subscribe({
        next: () => {
          if (this.employee.firstLogin) {
            this.updateFirstLogin();
          } else {
            this.closeDialog();
          }
          this.toastr.success('Your password has been changed');
        },
        error: (err: any) => {
          console.warn(err);
          this.toastr.error('Failed to change password');
        },
      }),
    );
  }

  private updateFirstLogin(): void {
    this.subscription.add(
      this.authService.setFirstLoginStatus(this.employee).subscribe({
        next: () => {
          this.closeDialog();
          this.toastr.success('Welcome to 42');
          this.employee.firstLogin = false;
          this.authService.setCurrentEmployee(this.employee);
        },
        error: () => {
          this.toastr.error('Failed to update first login status');
        },
      }),
    );
  }

  public closeDialog(): void {
    this.closePasswordDialog.emit(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
