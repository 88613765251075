import { Component } from '@angular/core';
import { faAngleDown, faLink } from '@fortawesome/free-solid-svg-icons';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { State, Type } from 'src/app/enum/book.enum';
import { IBook, IBookDialog, IBookUser } from 'src/app/models/book.model';
import { IConfirmDataBook, IConfirmDialogData } from 'src/app/models/confirm-data';
import {
  IBookResponse,
  IBooksResponse,
  ISuggestionResponse,
  ISuggestionsResponse,
} from 'src/app/models/response.model';
import { ISuggestion } from 'src/app/models/suggestion.model';
import { FilterByCategoryPipe } from 'src/app/pipes/filter-by-category.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LibraryService } from 'src/app/services/library.service';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrl: './library.component.scss',
})
export class LibraryComponent {
  private subscription = new Subscription();

  public allBooksSuggestions: ISuggestion[] = [];
  public allAvailableBooks: IBook[] = [];
  public dataToConfirm: IConfirmDataBook = {} as IConfirmDataBook;
  public selectedBook: IBook = {} as IBook;
  public selectedSortCategory = 'Category';

  public confirmDialog = false;
  public showLibraryDialog = false;
  public showBookDropdown = false;
  public showSortDropdown = false;
  public dropDownIcon = faAngleDown;
  public faLink = faLink;
  public Type = Type;

  public filteredBooks: IBook[] = [];

  public showConfirmationDialog = false;
  public bookRequestData: IConfirmDialogData = {} as IConfirmDialogData;
  public acceptButtonText: string;
  public isRequestContext = true;
  constructor(
    private toastr: ToastrService,
    public authService: AuthService,

    private filterByCategoryPipe: FilterByCategoryPipe,
    private libraryService: LibraryService,
  ) {}

  ngOnInit(): void {
    this.getAllBooks();
    this.getAllSuggestions();
  }

  private getAllSuggestions(): void {
    this.subscription.add(
      this.libraryService.getAllSuggestions().subscribe({
        next: (res: ISuggestionsResponse) => {
          this.allBooksSuggestions = res.DATA;
          if (res.DATA.length > 0) {
            this.allBooksSuggestions.sort((a, b) => b.upvotes - a.upvotes);
          }
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public onAcceptConfirmationDialog(data: IConfirmDialogData): void {
    const book = { ...data.book } as IBook;
    if (this.isRequestContext) {
      this.setCurrentUserForRequest(book);
    } else {
      this.setNewUserForBook(book);
    }

    this.updateBook(book, this.isRequestContext);
    this.showConfirmationDialog = false;
  }

  private getAllBooks(): void {
    this.subscription.add(
      this.libraryService.getAllBooks().subscribe({
        next: (res: IBooksResponse) => {
          this.allAvailableBooks = res.DATA;
          this.filteredBooks = this.filterByCategoryPipe.transform(this.allAvailableBooks, this.selectedSortCategory);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public manageLibraryDialogHandler(event: Event, bookId?: string, arrayType?: 'suggestion' | 'available'): void {
    this.dataToConfirm = {} as IConfirmDataBook;
    let target: string = this.getTarget(event);
    if (target === 'Book') {
      this.addLibraryNewBook();
    } else if (target === 'Suggestion') {
      this.addLibraryBookProposal();
    } else if (target.toString() === 'edit') {
      this.setBookForEdit(bookId, arrayType);
    } else if (target.toString() === 'delete') {
      this.removeBooksDialog(bookId, arrayType);
    }
  }

  private getTarget(event: Event): string {
    let targetElement = event.target as HTMLInputElement | HTMLImageElement | HTMLElement;
    let target: string;
    if (targetElement instanceof HTMLInputElement) {
      target = targetElement.value;
    } else if (targetElement instanceof HTMLImageElement) {
      target = targetElement.alt;
    } else {
      target = targetElement.innerText;
    }
    return target;
  }

  public updateBookCard(data: any): void {
    if (data.arrayType === 'suggestion') {
      this.updateBookSuggestion(data as ISuggestion);
    } else {
      if (data.newRequest) {
        this.requestAndUpdateBook(data, data.newRequest);
      } else {
        data.newRequest = false;
        this.requestAndUpdateBook(data, data.newRequest);
      }
    }
  }

  private requestAndUpdateBook(data: IBook, newRequest: boolean): void {
    this.subscription.add(
      this.libraryService.updateBook(data, newRequest).subscribe({
        next: () => {
          const book = this.allAvailableBooks.find((book) => book._id === data._id);
          if (book) {
            Object.assign(book, data);
          }
          this.getAllBooks();
          this.toastr.success('The book has been updated.');
          this.toggleLibraryDialog();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private updateBookSuggestion(data: ISuggestion): void {
    this.subscription.add(
      this.libraryService.updateSuggestion(data).subscribe({
        next: () => {
          const book = this.allBooksSuggestions.find((book) => book._id === data._id);
          if (book) {
            Object.assign(book, data);
          }
          this.toastr.success('Book updated');
          this.toggleLibraryDialog();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private removeBooks(bookId: string, arrayType: 'suggestion' | 'available'): void {
    if (arrayType === 'suggestion') {
      this.removeSuggestion(bookId);
    } else {
      this.removeBook(bookId);
    }
  }

  private removeSuggestion(bookId: string): void {
    const book = this.allBooksSuggestions?.find((book) => book._id === bookId);
    if (book) {
      this.allBooksSuggestions = this.allBooksSuggestions.filter((item) => item._id !== book._id);
    }
  }

  private removeBook(bookId: string): void {
    const book = this.allAvailableBooks?.find((book) => book._id === bookId);
    if (book) {
      this.filteredBooks = this.allAvailableBooks.filter((item) => item._id !== book._id);
      this.allAvailableBooks = this.allAvailableBooks.filter((item) => item._id !== book._id);
    }
  }

  public createNewBookCard(data: IBookDialog): void {
    if (data.arrayType === 'suggestion') {
      data.upvotes = 0;
      this.createNewSuggestion(data as ISuggestion);
    } else {
      this.createNewBook(data as IBook);
    }
  }

  private createNewBook(data: IBook): void {
    this.subscription.add(
      this.libraryService.createBook(data).subscribe({
        next: (res: IBookResponse) => {
          this.allAvailableBooks.push(res.DATA);
          this.toastr.success('The book has been added to the library.');
          this.toggleLibraryDialog();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private updateBook(book: IBook, newRequest: boolean): void {
    this.subscription.add(
      this.libraryService.updateBook(book, newRequest).subscribe({
        next: () => {
          this.getAllBooks();
          this.toastr.success('The book has been updated.');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private createNewSuggestion(data: ISuggestion): void {
    this.subscription.add(
      this.libraryService.createSuggestion(data).subscribe({
        next: (res: ISuggestionResponse) => {
          this.allBooksSuggestions.push(res.DATA);
          this.toastr.success('The book suggestion has been added.');
          this.toggleLibraryDialog();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private setBookForEdit(bookId: string, arrayType: 'suggestion' | 'available'): void {
    if (arrayType === 'suggestion') {
      this.selectedBook = this.allBooksSuggestions.find((book) => book._id === bookId);
    } else {
      this.selectedBook = this.allAvailableBooks.find((book) => book._id === bookId) as IBookDialog;
    }
    this.editLibraryBook(arrayType);
  }

  public onLendBookClick(book: IBook) {
    this.setLendBookDialog(book);
    this.acceptButtonText = 'Lend Book';
    this.isRequestContext = false;
    this.showConfirmationDialog = true;
  }
  public onSendBorrowBookRequestClick(book: IBook) {
    this.setBorrowBookDialog(book);

    this.acceptButtonText = 'Send Request';
    this.isRequestContext = true;
    this.showConfirmationDialog = true;
  }

  public onSortCategory(value: string): void {
    this.selectedSortCategory = value;
    this.filteredBooks = this.filterByCategoryPipe.transform(this.allAvailableBooks, this.selectedSortCategory);
    this.showSortDropdown = false;
  }

  public proofAndSetVote(id: string, currentBook: ISuggestion): ActiveToast<any> | void {
    const isVoted = this.proofDoubleVote(id);
    if (isVoted) {
      return this.toastr.error('You have already voted for this book.');
    }
    currentBook.upvotes++;
    this.setVote(id, currentBook);
  }

  private proofDoubleVote(dataId: string): boolean {
    const employeeId = this.authService.getCurrentEmployee()?._id;
    const data = this.allBooksSuggestions.find((book) => book._id === dataId);
    const isVoted = data?.hasVoted.includes(employeeId);
    return isVoted;
  }

  private setVote(id: string, currentBook: ISuggestion): void {
    this.subscription.add(
      this.libraryService.voteSuggestion(id, currentBook.upvotes).subscribe({
        next: (res: IBookResponse) => {
          const book = this.allBooksSuggestions.find((book) => book._id === res.DATA._id);
          if (book) {
            Object.assign(book, res.DATA);
          }
          this.toastr.success('Your vote has been submitted.');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  //!Type
  public deleteBooks(bookId: string, event: any): void {
    const arrayType = event.arrayType as 'suggestion' | 'available';
    const isBought = event.isBought;
    if (arrayType === 'available') {
      this.deleteBook(bookId, arrayType);
    } else {
      this.deleteSuggestion(bookId, arrayType, isBought);
    }
  }

  private deleteBook(bookId: string, arrayType: 'suggestion' | 'available'): void {
    this.subscription.add(
      this.libraryService.deleteBook(bookId).subscribe({
        next: () => {
          this.removeBooks(bookId, arrayType);
          this.toastr.success('The book has been successfully deleted from the library.');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private deleteSuggestion(bookId: string, arrayType: 'suggestion' | 'available', isBought: 'true' | 'false'): void {
    this.subscription.add(
      this.libraryService.deleteSuggestion(bookId, isBought).subscribe({
        next: (res: ISuggestionResponse) => {
          if (isBought == 'true') {
            this.allAvailableBooks.push(res.DATA);
            this.toastr.success('You buy the book and it has been added to the library.');
          } else {
            this.toastr.success('The book suggestion has been successfully deleted.');
          }
          this.removeBooks(bookId, arrayType);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public removeBooksDialog(bookId: string, arrayType: 'suggestion' | 'available'): void {
    this.confirmDialog = true;
    this.selectedBook._id = bookId;
    if (arrayType === 'suggestion') {
      this.setTextForDeleteSuggestionDialog();
    } else {
      this.setTextForDeleteBookDialog();
    }
  }

  public onToggleDropDown(type: 'book' | 'sort'): void {
    if (type === 'book') {
      this.showBookDropdown = !this.showBookDropdown;
    } else {
      this.showSortDropdown = !this.showSortDropdown;
    }
  }

  public toggleLibraryDialog(): void {
    this.showLibraryDialog = !this.showLibraryDialog;
  }

  private editLibraryBook(arrayType: 'suggestion' | 'available'): void {
    this.dataToConfirm.headline = 'Edit Book';
    this.dataToConfirm.edit = true;
    this.dataToConfirm.arrayType = arrayType;
    this.toggleLibraryDialog();
  }

  private addLibraryNewBook(): void {
    this.dataToConfirm.headline = 'Add a new Book to Library';
    this.dataToConfirm.arrayType = 'available';
    this.toggleLibraryDialog();
  }

  private addLibraryBookProposal(): void {
    this.dataToConfirm.headline = 'Create a new purchase suggestion';
    this.dataToConfirm.arrayType = 'suggestion';
    this.toggleLibraryDialog();
  }

  private setCurrentUserForRequest(book: IBook): void {
    book.requestedBy = {} as IBookUser;
    book.requestedBy._id = this.authService.getCurrentEmployee()._id;
    book.requestedBy.firstname = this.authService.getCurrentEmployee().firstname;
    book.requestedBy.lastname = this.authService.getCurrentEmployee().lastname;
  }

  private setNewUserForBook(book: IBook): void {
    book.currentHolderId = book.requestedBy;
    book.requestedBy = null;
  }

  private setLendBookDialog(book: IBook): void {
    this.bookRequestData.headline = 'Book - Confirmation';
    this.bookRequestData.text = `I confirm to lend the book ${book.title}`;
    this.bookRequestData.book = book;
  }

  private setBorrowBookDialog(book: IBook): void {
    const holderName = book.currentHolderId.firstname + ' ' + book.currentHolderId.lastname;
    this.bookRequestData.headline = 'Book - Request';
    this.bookRequestData.text = `This will send an email to ${holderName}, requesting to lend the book.`;
    this.bookRequestData.book = book;
  }

  private setTextForDeleteSuggestionDialog(): void {
    this.dataToConfirm.headline = 'Delete this Suggestion ?';
    this.dataToConfirm.text = `Are you sure you want to delete this suggestion from the library?`;
    this.dataToConfirm.image = 'delete';
    this.dataToConfirm.arrayType = 'suggestion';
  }

  private setTextForDeleteBookDialog(): void {
    this.dataToConfirm.headline = 'Delete this Book ?';
    this.dataToConfirm.text = `Are you sure you want to delete this book from the library?`;
    this.dataToConfirm.image = 'delete';
    this.dataToConfirm.arrayType = 'available';
  }

  public isUserAllowedToEdit(book): boolean {
    return (
      this.authService.getCurrentEmployee().isAdmin ||
      this.authService.getCurrentEmployee()._id === book.ownerId?._id ||
      this.authService.getCurrentEmployee()._id === book.suggestedBy
    );
  }

  /**
   * Determines whether the request button should be visible for a given book and current user.
   * @param {IBook} book - The book for which to determine button visibility.
   * @returns {boolean} - True if the request button should be visible, false otherwise.
   */
  public isRequestButtonVisible(book: IBook): boolean {
    // Get the ID of the current user
    const currentUser = this.authService.getCurrentEmployee()?._id;

    // Check if the book is available and there are no pending requests
    const isAvailableWithoutPendingRequest = book.state === State.Available && !book.requestedBy;

    // Check if the current user is the owner of the book
    const isCurrentUserOwner = book.ownerId?._id === currentUser;

    // Check if the current user is the holder of the book
    const isCurrentUserHolder = book.currentHolderId?._id === currentUser;

    // Check if the current user has requested the book
    const isRequestedByCurrentUser = book.requestedBy?._id === currentUser;

    // Check if the book is held by the owner and requested by the same user
    const isHeldByOwnerAndRequestedBySameUser =
      book.currentHolderId?._id === book.ownerId?._id && book.currentHolderId?._id === book.requestedBy?._id;

    // If the book is held by the owner and requested by the same user who is the current user, do not show the button
    if (isHeldByOwnerAndRequestedBySameUser && currentUser === book.ownerId?._id) {
      return false;
    }

    // Determine if the button should be visible based on various conditions
    const isButtonVisible =
      (isAvailableWithoutPendingRequest && !isCurrentUserOwner && !isCurrentUserHolder && !isRequestedByCurrentUser) ||
      (isCurrentUserOwner && !isCurrentUserHolder && !isRequestedByCurrentUser && book.state === State.Available) ||
      (isHeldByOwnerAndRequestedBySameUser && !isCurrentUserHolder);

    // Return whether the button should be visible
    return isButtonVisible;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
