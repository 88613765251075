<header
  class="fixed left-0 right-0 z-50 flex items-center justify-between bg-charcoal px-4 font-helvetica text-white shadow-md sm:px-6"
>
  <a href="#" class="z-[51] flex items-center justify-center gap-x-4">
    <img src="assets/img/42.png" class="h-12 invert" alt="Logo" />
    <h1 class="hidden text-3xl smC:block">PREY</h1>
  </a>
  <div class="flex">
    <div class="header sticky top-0 z-[50] flex h-16 w-full max-w-7xl justify-end bg-charcoal">
      <div class="group relative flex h-full items-center">
        <div class="group">
          <ul
            class="absolute -right-4 mt-8 flex origin-top scale-0 transform flex-col gap-1 bg-charcoal transition duration-150 ease-in-out group-hover:scale-100 sm:-right-6"
          >
            @if (employee?.role !== EmployeeRole.TESTER) {
              <li
                (click)="passwordDialog = true"
                class="flex cursor-pointer gap-2 rounded-sm px-4 py-1 hover:bg-gray-700"
              >
                <fa-icon [icon]="password"></fa-icon>
                <p>Change Password</p>
              </li>
            }

            <li (click)="logout()" class="flex cursor-pointer gap-2 rounded-sm px-4 py-1 hover:bg-gray-700">
              <fa-icon [icon]="logoutIcon"></fa-icon>
              <p>Logout</p>
            </li>
          </ul>
        </div>
        <div class="flex items-center gap-2 pr-4 sm:pr-20">
          <span class="sm:tracking-wider" data-cy="coin-balance"
            >Balance: {{ userCoinsBalance ? userCoinsBalance : 0 }}</span
          >
          <img class="h-4" src="../../../assets/img/coin.png" alt="coin" />
        </div>
        <h1 class="text-xl">{{ employee?.firstname?.charAt(0) }}. {{ employee?.lastname }}</h1>
      </div>
    </div>
    @if (passwordDialog) {
      <app-change-pw class="z-[60] max-lg:w-full" (closePasswordDialog)="this.passwordDialog = false"></app-change-pw>
    }
  </div>
</header>
