import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IEmployee } from 'src/app/models/employee.model';
import { ILeave } from 'src/app/models/leave.model';
import { EmployeeResponse } from 'src/app/models/response.model';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  private subscription = new Subscription();
  public mySortedLeaves: ILeave[] = [];
  public passwordDialog = false;

  public employeeBirthdayData: IEmployee[] = [];

  constructor(
    private leaveService: LeaveService,
    private employeeService: EmployeeService,
    public authService: AuthService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.checkEmployeeFirstLogin();
    this.fetchAllLeaves();
    this.fetchNextBirthday();
  }

  private fetchNextBirthday(): void {
    this.subscription.add(
      this.employeeService.getNextBirthday().subscribe({
        next: (res: EmployeeResponse[]) => {
          this.employeeBirthdayData = res['DATA'];
        },
        error: () => {
          this.toastr.error('Error loading next birthday');
        },
      }),
    );
  }

  private checkEmployeeFirstLogin(): void {
    let employee = this.authService.getCurrentEmployee();
    if (employee.firstLogin) {
      this.passwordDialog = true;
      this.toastr.success('Please Enter a new Password');
    }
  }

  private fetchAllLeaves(): void {
    this.subscription.add(
      this.leaveService.getAllLeaves().subscribe({
        next: (res) => {
          let allLeaves = res['DATA'];
          this.filterLeavesCurrentEmployee(allLeaves);
        },
        error: (err) => {
          console.log(err);
          this.toastr.error('Error loading leaves');
        },
      }),
    );
  }

  private filterLeavesCurrentEmployee(allLeaves: ILeave[]): void {
    let myLeaves = allLeaves.filter((leave) => leave.employeeId === this.authService.getCurrentEmployee()._id);
    this.sortLeavesByStartDate(myLeaves);
  }

  private sortLeavesByStartDate(myLeaves: ILeave[]): void {
    this.mySortedLeaves = myLeaves.sort((first, last) => {
      return new Date(first.startDate).getTime() - new Date(last.startDate).getTime();
    });
  }

  public navigateToUrl(url: string): void {
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
