import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IEmployee } from 'src/app/models/employee.model';
import { ILeaveDialog } from 'src/app/models/leave-dialog.model';
import { ILeave } from 'src/app/models/leave.model';

import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-leave-dialog',
  templateUrl: './leave-dialog.component.html',
  styleUrls: ['./leave-dialog.component.scss'],
})
export class LeaveDialogComponent {
  @Output() editLeaveData = new EventEmitter<ILeave>();
  @Output() addLeaveData = new EventEmitter<ILeave>();
  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() deleteLeave = new EventEmitter<ILeave>();

  @Input() selectedLeave: ILeave;
  @Input() info: ILeaveDialog;
  @Input() allLeaves: ILeave[];
  @Input() employees: IEmployee[];

  public employeeID: string;
  public minStartDate = new Date();
  public minEndDate: Date;

  public leaveEvents: ILeave[] = [];

  public forAnotherEmployee = false;

  public startDate: string;
  public endDate: string;

  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    public helperService: HelperService,
  ) {}

  ngOnInit(): void {
    if (this.info.type === 'edit') {
      this.startDate = this.formattDate(this.selectedLeave.startDate as Date);
      this.endDate = this.formattDate(this.selectedLeave.endDate as Date);
      this.minStartDate = new Date();
      this.minEndDate = new Date(this.startDate);
    }

    this.allLeaves.forEach((e) => {
      this.leaveEvents.push(e);
    });
  }

  public onSelectStartDate(event: Event) {
    this.startDate = this.helperService.dateToISOString(event);
    this.minEndDate = new Date(this.startDate);
  }

  public onSelectEndDate(event: Event) {
    this.endDate = this.helperService.dateToISOString(event);
  }

  // sets minimum start date on current day for datepicker
  private setMinDate(): void {
    document.getElementById('startDate').setAttribute('min', new Date().toISOString().split('T')[0]);
  }

  public onSelectEmployee(employee: IEmployee) {
    this.employeeID = employee._id;
  }

  /**
   * Formattes date to string for NgModel to display it.
   * @param {Date} date - Date that is clicked.
   */
  private formattDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  /**
   * Fills leaveOutput object with leave information and type
   */
  public onSubmit(): void {
    if (this.selectedLeave.startDate > this.selectedLeave.endDate) {
      this.toastr.error('Your leave cannot end before it begins!');
    } else {
      if (this.info.type === 'add' && this.forAnotherEmployee && this.employeeID) {
        const employee = this.employees.find((e) => e._id === this.employeeID);
        this.selectedLeave.employeeId = this.employeeID;
        this.selectedLeave.employeeName = employee.firstname;
      }

      this.selectedLeave.startDate = this.startDate as string;
      this.selectedLeave.endDate = this.endDate as string;
      this.selectedLeave.calendarStartDate = this.startDate as string;
      this.selectedLeave.calendarEndDate = this.endDate as string;

      this.checkExistingEvents();
    }
  }

  private addNewLeave(): void {
    this.addLeaveData.emit(this.selectedLeave);
  }

  private editLeave(): void {
    this.editLeaveData.emit(this.selectedLeave);
  }

  /**
   * Checks wheter a leave already exists after adding it
   */
  private checkExistingEvents(): void {
    const newLeave = this.selectedLeave;

    let existingLeaves = this.leaveEvents.find(
      (leave) =>
        (leave.startDate <= newLeave.endDate &&
          leave.endDate >= newLeave.endDate &&
          leave.employeeId === newLeave.employeeId &&
          newLeave._id != leave._id) ||
        (leave.startDate <= newLeave.startDate &&
          leave.endDate >= newLeave.startDate &&
          leave.employeeId === newLeave.employeeId &&
          newLeave._id != leave._id),
    );

    if (existingLeaves) {
      this.toastr.error('There is already an event in this timespan');
    } else if (this.info.type === 'add') {
      this.addNewLeave();
    } else if (this.info.type === 'edit') {
      this.editLeave();
    }
  }

  // toggle for opening employee input
  public anotherEmployee(event: any): void {
    this.forAnotherEmployee = event.checked;
  }

  public onClose(): void {
    this.closeDialog.emit(true);
  }

  public onDelete(): void {
    this.deleteLeave.emit(this.selectedLeave);
  }
}
