<div
  class="min-w-screen animated fadeIn faster fixed inset-0 z-50 flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat outline-none focus:outline-none"
>
  <div class="absolute inset-0 z-0 bg-black opacity-30"></div>
  <div class="relative m-auto w-full max-w-[50rem] rounded-xl bg-lightGrayish p-8 shadow-lg">
    <div class="flex w-full justify-center">
      <h1 class="text-xl font-bold">
        {{ DialogType.OVERTIME === data.type ? 'Overtime Balance History' : 'Coin Transfer History' }}
      </h1>
    </div>

    <div class="flex flex-col p-5 text-sm" data-cy="history-dialog">
      <!-- Coin History -->
      @if (data.type === DialogType.COINBALANCE) {
        <div class="mb-6 flex w-full flex-col justify-center gap-2 sm:flex-row">
          <div class="flex gap-2">
            <span> Employee: </span>
            <span class="text-sunflowerGold">{{ data.employee?.firstname }} {{ data.employee?.lastname }}</span>
          </div>
          <div class="flex gap-2">
            <span> Current Balance:</span>
            <span class="text-sunflowerGold" data-cy="current-balance">{{ data.employee?.coinBalance ?? 0 }}</span>
          </div>
        </div>
        <div [ngClass]="coinTransferHistory.length > 0 ? 'h-96' : 'h-36'" class="overflow-auto pr-2">
          <div class="sticky top-0 z-10 flex bg-lightGrayish pb-2 pl-[10.75rem] text-xs font-bold">
            <span class="min-w-[18rem] max-w-[18rem] flex-1">Reason</span>
            <span class="w-28 min-w-[7rem]">Issuer</span>
            <span class="w-28 min-w-[7rem]">Coin Balance</span>
          </div>
          @if (coinTransferHistory.length > 0) {
            @for (history of coinTransferHistory; track history) {
              <div class="flex items-center">
                <div
                  class="flex h-16 w-28 min-w-[7rem] items-center border-r-2 border-gray-300"
                  data-cy="history-entry-date"
                >
                  <span>{{ history.transferDate | date: 'dd.MM.yyyy' }}</span>
                </div>
                <div class="relative items-center">
                  <div class="w-12 min-w-[3rem] border-t-2 border-gray-300"></div>

                  <div
                    class="bg absolute left-[-1.25rem] top-[-1.25rem] flex h-10 w-10 items-center justify-center rounded-full border-2 border-[#f8f8f8] text-[11px] text-white"
                    data-cy="history-entry-amount"
                    [ngClass]="history.amount >= 0 ? 'bg-green-500' : 'bg-red-300'"
                  >
                    {{ history.amount > 0 ? '+' + history.amount : history.amount }}
                  </div>
                </div>

                <div
                  class="flex h-[3.25rem] flex-1 items-center rounded-md border-2 p-2"
                  [ngClass]="history.amount > 0 ? 'border-gray-300' : 'border-gray-300'"
                >
                  <div class="min-w-[18rem] max-w-[18rem] flex-1 overflow-hidden text-ellipsis whitespace-nowrap pr-3">
                    <span [title]="history.reason">{{ history.reason }}</span>
                  </div>
                  <div class="w-28 min-w-[7rem] overflow-hidden text-ellipsis whitespace-nowrap">
                    <span>{{ getEmployee(history.issuer) }}</span>
                  </div>
                  <div class="w-28 min-w-[7rem]" data-cy="history-entry-balance">
                    <span>{{ history.currentBalance }}</span>
                  </div>
                </div>
              </div>
            }
          } @else {
            <div class="mt-4 text-center">There is no Coin History for this employee</div>
          }
        </div>
      } @else {
        <!-- Overtime History -->
        <div class="mb-6 flex w-full flex-col justify-center gap-2 sm:flex-row">
          <div class="flex gap-2">
            <span> Employee: </span>
            <span class="text-sunflowerGold">{{ data.employee?.firstname }} {{ data.employee?.lastname }}</span>
          </div>
          <div class="flex gap-2">
            <span> Current Balance:</span>
            <span class="text-sunflowerGold" data-cy="current-balance">{{
              getHoursFromMinutes(data.employee.overtimeBalance) ?? 0
            }}</span>
          </div>
        </div>
        <div [ngClass]="overtimeBalanceHistory.length > 0 ? 'h-96' : 'h-36'" class="overflow-auto pr-2">
          <div class="sticky top-0 z-10 flex bg-lightGrayish pb-2 pl-[11.75rem] text-xs font-bold">
            <span class="min-w-[18rem] max-w-[18rem] flex-1">Description</span>

            <span class="w-28 min-w-[7rem]">Issuer</span>
            <span class="w-28 min-w-[7rem]">Overt. Balance</span>
          </div>

          @if (overtimeBalanceHistory.length > 0) {
            @for (history of overtimeBalanceHistory; track history) {
              <div class="flex items-center" data-cy="history-entry-row">
                <div
                  class="flex h-16 w-28 min-w-[7rem] items-center border-r-2 border-gray-300"
                  data-cy="history-entry-date"
                >
                  <span>{{ history.createdAt | date: 'dd.MM.yyyy' }}</span>
                </div>

                <div class="relative items-center">
                  <div class="w-12 min-w-[4rem] border-t-2 border-gray-300"></div>

                  <div
                    class="bg absolute left-[-1.35rem] top-[-1.35rem] flex h-11 w-11 items-center justify-center rounded-full border-2 border-[#f8f8f8] text-[11px] text-white"
                    data-cy="history-entry-amount"
                    [ngClass]="history.timeAmount > 0 ? 'bg-green-500' : 'bg-red-300'"
                  >
                    {{
                      history.timeAmount > 0
                        ? '+' + getHoursFromMinutes(history.timeAmount)
                        : getHoursFromMinutes(history.timeAmount)
                    }}
                  </div>
                </div>

                <div class="flex h-12 flex-1 items-center rounded-md border-2 border-gray-300 p-2">
                  <div class="min-w-[18rem] max-w-[18rem] flex-1 overflow-hidden text-ellipsis whitespace-nowrap pr-3">
                    <span [title]="history.description">{{ history.description }}</span>
                  </div>

                  <div class="w-28 min-w-[7rem] overflow-hidden text-ellipsis whitespace-nowrap">
                    <span>{{ getEmployee(history.userId) }}</span>
                  </div>
                  <div class="w-28 min-w-[7rem]" data-cy="history-entry-balance">
                    <span> {{ getHoursFromMinutes(history.newOvertimeBalance) }}</span>
                  </div>
                </div>
              </div>
            }
          } @else {
            <div class="mt-4 text-center">There is no Overtime History for this employee</div>
          }
        </div>
      }
      <div class="mt-4 flex w-full justify-end">
        <button
          (click)="onClose()"
          class="grayText mb-2 flex h-9 items-center border bg-white px-5 py-2 text-sm font-medium tracking-wider shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
          data-cy="pi-reject-del-button"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
