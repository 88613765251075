import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IProduct } from 'src/app/models/product.model';
import { ITransaction } from 'src/app/models/transaction.model';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
})
export class ShopComponent {
  private subscription = new Subscription();
  public paymentHistory = [] as ITransaction[];
  public products = [] as IProduct[];

  constructor(
    private transactionService: TransactionService,
    private toastr: ToastrService,
  ) {
    this.getLatestTransactions();
    this.getAllProducts();
  }

  public getLatestTransactions() {
    this.subscription.add(
      this.transactionService.getLatestTransactions().subscribe({
        next: (res) => {
          this.paymentHistory = res.DATA;
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  private getAllProducts() {
    this.subscription.add(
      this.transactionService.getAllProducts().subscribe({
        next: (res) => {
          this.products = res.DATA.sort((a, b) => a.price - b.price);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
