<div class="flex flex-col bg-lightGrayish px-6 max-lg:mb-5">
  <div class="flex items-center text-veryFaintBlack max-lg:justify-between">
    <p class="w-28 text-xs font-semibold lg:w-[85%] lg:text-base">
      {{ firstText }} <br />
      {{ secondText }}
    </p>
    @if (type) {
      <p
        class="flex w-64 justify-end whitespace-nowrap border-l-2 text-xs font-semibold text-black max-sm:w-28 lg:text-base xl:min-w-[5rem]"
      >
        {{
          secondType
            ? nextBirthdayData[thirdType] +
              '&nbsp;' +
              nextBirthdayData[secondType]?.charAt(0) +
              '. ' +
              nextBirthdayData[type]
            : helperService.formatMinutes(authService.getCurrentEmployee()[type])
        }}
        {{ subText }}
      </p>
    }
    @if (!type) {
      <p class="flex w-64 min-w-max justify-end border-l-2 text-xs font-semibold text-black max-sm:w-28 lg:text-base">
        {{ mySortedLeaves[0]?.startDate ? (mySortedLeaves[0].startDate | date: 'dd MMM') : '' }}. -
        {{ mySortedLeaves[0]?.endDate ? (mySortedLeaves[0].endDate | date: 'dd MMM') : '' }}.
      </p>
    }
  </div>
</div>
