<div class="grid justify-between gap-4 md:grid-cols-2">
  @for (product of products; track product) {
    <div class="flex-col" data-cy="product-element">
      <div
        [ngClass]="{
          'overflow-hidden': product.type === EProductType.CLOTHING,
          '': product.type === EProductType.FOOTWEAR,
        }"
        class="group relative"
      >
        <img
          (click)="onOpenShopDialog(product)"
          class="w-full cursor-pointer"
          [ngClass]="{ 'object-cover group-hover:translate-y-0': product.type }"
          src="../../../assets/img/products/{{ product.imgUrl }}.png"
        />
        @if (product.type === EProductType.CLOTHING) {
          <div
            class="absolute bottom-0 flex w-full translate-y-full transform gap-4 bg-white p-2 font-medium text-veryFaintBlack transition-transform duration-200 group-hover:translate-y-0 max-lg:text-sm"
            data-
          >
            <span (click)="onOpenShopDialog(product, EClothingSize.XS)" class="cursor-pointer hover:text-black"
              >XS</span
            >
            <span (click)="onOpenShopDialog(product, EClothingSize.S)" class="cursor-pointer hover:text-black">S</span>
            <span (click)="onOpenShopDialog(product, EClothingSize.M)" class="cursor-pointer hover:text-black">M</span>
            <span (click)="onOpenShopDialog(product, EClothingSize.L)" class="cursor-pointer hover:text-black">L</span>
            <span (click)="onOpenShopDialog(product, EClothingSize.XL)" class="cursor-pointer hover:text-black"
              >XL</span
            >
          </div>
        }
      </div>
      <div class="flex flex-col gap-1 pb-8 pt-2">
        <span class="font-medium max-lg:text-sm">{{ product.title }}</span>
        <span
          class="clamp-2 -mt-1 max-h-[2.4em] min-h-[2.4em] overflow-hidden overflow-ellipsis text-xs font-medium text-veryFaintBlack max-lg:text-[10px]"
          >{{ product.description }}</span
        >
        <span class="flex items-center gap-2 text-sm font-medium max-lg:text-xs" data-cy="product-price"
          >{{ product.price }} Coins <img class="flex h-4" src="../../../assets/img/coin.png"
        /></span>
      </div>
    </div>
  }
</div>
@if (showShopDialog) {
  <app-shop-dialog
    [product]="selectedProduct"
    (closeDialog)="closeShopDialog()"
    (acceptDialog)="onBuyProduct($event)"
  ></app-shop-dialog>
}
