<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl flex-col gap-5">
    <div class="mb-5 flex flex-col gap-4 bg-lightGrayish p-5 xl:gap-5">
      <div>
        <app-text-content-box [headline]="'Working Time'" [headerIcon]="'🕖'" class="flex xl:pb-2">
        </app-text-content-box>
      </div>

      <div class="flex max-xl:flex-col max-xl:items-center">
        <div class="flex w-2/5 items-center gap-4 pr-4 max-xl:w-full">
          <div class="relative flex items-center max-xl:w-full">
            <input
              type="search"
              class="remove-arrow block h-12 border border-[#D1D1D1] p-2.5 pr-6 text-sm placeholder-veryTransparentBlack placeholder:font-semibold focus:border-sunflowerGold focus:ring-sunflowerGold max-xl:w-full xl:w-44 min_w_1640:w-[216px]"
              data-cy="overtime-search-employees"
              placeholder="Search employee"
              [(ngModel)]="searchEmployee"
            />
            <img class="absolute right-4 h-4" src="../../../assets/img/icons/search.png" alt="search" />
          </div>

          <label class="text-sm font-medium">Archived</label>
          <input
            type="checkbox"
            [(ngModel)]="showArchivedEmployees"
            [ngModelOptions]="{ standalone: true }"
            class="flex h-5 w-9 min-w-[2rem] appearance-none items-center rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          />
        </div>
        @if (employees?.length > 0) {
          <app-edit-overtime
            class="w-full"
            (employeeUpdate)="updateEmployee($event)"
            [employees]="employees"
          ></app-edit-overtime>
        }
      </div>
      <div>
        <div class="flex items-center justify-center p-1 text-lg font-medium">
          <img
            (click)="onChangeWeek('last')"
            class="h-4 cursor-pointer text-lg"
            src="assets/img/icons/arrow-left.png"
            alt="arrow-left"
          />
          <div class="flex w-52 justify-center">{{ currentWeek }}</div>
          <img
            (click)="onChangeWeek('next')"
            class="h-4 rotate-180 cursor-pointer text-lg"
            data-cy="workweek-next-week"
            src="assets/img/icons/arrow-left.png"
            alt="arrow-left"
          />
        </div>
        <div class="h-auto max-h-[540px] overflow-y-auto">
          <table data-cy="overtime-table">
            <thead class="sticky top-0 z-10 bg-black text-center text-white">
              <tr>
                <th class="px-4 py-2 text-left font-semibold">Employee</th>
                <th class="px-4 py-2 text-left font-semibold">Total Overtime</th>
                <th class="px-4 py-2 text-left font-semibold">Overtime (This Week)</th>
                <th class="px-4 py-2 text-left font-semibold">Weekly Working Time</th>
                <th class="px-4 py-2 text-left font-semibold">Actual</th>
              </tr>
            </thead>
            <tbody>
              @for (employeesWorkWeek of getWorkWeeks() | filterByName: searchEmployee; track employeesWorkWeek) {
                <tr
                  [ngClass]="{ 'bg-red-50': employeesWorkWeek.employeeId.isArchived }"
                  class="mb-2 table-row flex-col flex-wrap sm:mb-0"
                  data-cy="workweek-employee-row"
                >
                  <app-employee-row class="contents" [workWeek]="employeesWorkWeek"></app-employee-row>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex w-full justify-end gap-4">
        <div
          (click)="openConfirmDialog()"
          class="flex h-12 w-40 cursor-pointer items-center justify-center gap-2 border-black bg-almostBlack font-semibold text-sunflowerGold"
          data-cy="save-button"
        >
          <span>Save</span>
          <img class="h-[18px] cursor-pointer" src="assets/img/icons/save.png" alt="save" />
        </div>
        <div
          (click)="fetchWorktimeInWeekForAllUsers()"
          class="flex h-12 w-40 cursor-pointer items-center justify-center gap-2 border-black bg-almostBlack font-semibold text-sunflowerGold"
          data-cy="validate-button"
        >
          <span>Validate</span>
          <img class="h-[24px] cursor-pointer" src="assets/img/icons/overtime.png" alt="Validate" />
        </div>
      </div>
    </div>
  </div>
</div>
@if (confirmDialog) {
  <app-confirm-dialog
    (closeDialog)="confirmDialog = false"
    (saveChanges)="saveWorkWeeks()"
    [data]="dataToConfirm"
  ></app-confirm-dialog>
}
