export enum State {
  Available = 'Available',
  Requested = 'Requested',
}

export enum BookCategory {
  ITArchitecture = 'IT & Architecture',
  Coding = 'Coding',
  Tools = 'Tools',
  Business = 'Business',
  Misc = 'Misc. (Other)',
}

export enum Type {
  book = 'Book',
  eBook = 'E-Book',
}
