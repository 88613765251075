import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IConfirmData } from 'src/app/models/confirm-data';
import { IGoal } from 'src/app/models/goal.model';
import { GoalsResponse } from 'src/app/models/response.model';
import { GoalsService } from 'src/app/services/goals.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-company-goals',
  templateUrl: './company-goals.component.html',
  styleUrl: './company-goals.component.scss',
})
export class CompanyGoalsComponent {
  private subscriptions = new Subscription();

  public allCompanyGoals: IGoal[] = [];
  public confirmDialog = false;
  public dataToConfirm = {} as IConfirmData;
  public currentGoal: IGoal;
  constructor(
    public router: Router,
    private helperService: HelperService,
    private goalService: GoalsService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getCompanyGoals();
    this.subscribeCompanyGoals();
  }

  private subscribeCompanyGoals(): void {
    this.subscriptions.add(
      this.helperService.companyGoals$.subscribe((goals: IGoal[]) => {
        this.allCompanyGoals = [...this.allCompanyGoals, ...goals];
      }),
    );
  }

  deleteCompanyGoal(goalId: string): void {
    this.subscriptions.add(
      this.goalService.deleteCompanyGoal(goalId).subscribe({
        next: () => {
          this.toastr.success('Company goal has been deleted');
          this.allCompanyGoals = this.allCompanyGoals.filter((goal: IGoal) => goal._id !== goalId);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public getCompanyGoals(): void {
    this.subscriptions.add(
      this.goalService.getAllCompanyGoals().subscribe({
        next: (res: GoalsResponse) => {
          this.allCompanyGoals = res.DATA;
        },
        error: () => {
          this.toastr.error('Company goals could not be loaded');
        },
      }),
    );
  }

  onDeleteCompanyGoal(goal): void {
    this.currentGoal = goal;
    this.confirmDialog = true;
    this.setConfirmDialog();
  }

  setConfirmDialog(): void {
    this.dataToConfirm.headline = 'Delete this Goal?';
    this.dataToConfirm.text = `Are you sure to delete this Goal?`;
    this.dataToConfirm.image = 'delete';
  }

  closeConfirmDialog(): void {
    this.confirmDialog = false;
  }
}
