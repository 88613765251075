import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshInProgress: boolean = false;
  private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.authService.getAccessToken();

    return next.handle(this.addToken(accessToken, req)).pipe(
      catchError((err) => {
        // in case of 401 http error (token expired)
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // get local refresh token
          const refreshToken = this.authService.getRefreshToken();
          // if there are tokens then send refresh token request
          if (refreshToken && accessToken) return this.refreshToken(req, next);
          // otherwise logout and redirect to login page
          else return this.logout(err);
        }
        // in case of 403 http error (refresh token failed)
        if (err instanceof HttpErrorResponse && err.status === 403) return this.logout(err);
        // if error has status neither 401 nor 403 then just return this error
        return throwError(() => err);
      }),
    );
  }

  // Add our current access token from the service if present
  private addToken(accessToken: string, req: HttpRequest<any>) {
    if (accessToken) {
      return req.clone({
        setHeaders: { auth: accessToken },
      });
    } else {
      return req;
    }
  }

  private logout(err: Error): Observable<HttpEvent<any>> {
    this.authService.logout();
    return throwError(() => err);
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshInProgress) {
      this.refreshInProgress = true;
      this.accessTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((res) => {
          this.refreshInProgress = false;
          this.accessTokenSubject.next(res['DATA'].accessToken);
          // repeat failed request with new token
          return next.handle(this.addToken(res['DATA'].accessToken, request));
        }),
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          // repeat failed request with new token
          return next.handle(this.addToken(token!, request));
        }),
      );
    }
  }
}
