<div class="flex flex-wrap justify-{{ position }} text-center  {{ gap }}">
  @for (value of ourValues; track value; let i = $index) {
    <div
      (mouseover)="hovering[i] = true"
      (mouseout)="hovering[i] = false"
      [ngClass]="{ 'mb-4': paddingBottom, 'bg-white': backgroudColor }"
      class=" {{
        width
      }}   p-5 items-center flex flex-col bg-lightGrayish border-b-2 transition-all hover-gradient border-[#e6ae48] hover:text-white"
    >
      @if (value.imgSrc) {
        <img
          class="w-8 z-10 pb-4 pt-1"
          [src]="hovering[i] ? value.imgSrc + '-white.svg' : value.imgSrc + '.svg'"
          [alt]="value.imgAlt"
        />
      }
      <p class="pb-2 font-bold text-sm lg:text-lg z-10">{{ value.title }}</p>
      <span class="text-xs lg:text-base z-10">
        {{ value.description }}
      </span>
    </div>
  }
</div>
