<app-sorting-segment (sortOptionSelected)="sortOptionSelected($event)"></app-sorting-segment>

@for (idea of helperService.ideas$ | async; track idea; let i = $index) {
<div class="flex flex-col">
  @if (!idea.isSolved) {
  <div class="flex flex-col items-center w-full center pt-5 mb-1 first:pt-2">
    @if (showRemoveButton()) {
    <button (click)="createRemoveModal(idea, 'box')" class="flex w-10 z-10 -mb-5">
      <img src="../../../assets/img/icons/solve.png" alt="solve" />
    </button>
    }
    <div
      class="flex flex-col items-center w-full relative p-3 max-lg:pt-5 lg:p-5 border-2 border-b-0 max-lg:text-xs bg-paleOrange border-sunflowerGold"
      data-cy="productidea-item"
    >
      {{ idea.text }}
      <div class="flex mt-5 gap-1 max-sm:flex-col-reverse w-full items-center flex-col relative justify-normal">
        <div class="flex items-center">
          <button
            (click)="toggleComments(i)"
            class="lg:left-2 left-0 absolute flex items-center gap-2"
            data-cy="productidea-comments-symbol"
          >
            <fa-icon class="lg:scale-125" [icon]="commentIcon"></fa-icon>
            <span class="text-xs lg:text-base"> {{ idea.comments.length }}</span>
          </button>

          <div class="flex gap-2 items-center">
            <p class="lg:text-2xl font-bold" data-cy="productidea-vote-count">{{ idea.voteCount }}</p>
            <button (click)="upvoteProductIdea(idea)" data-cy="pi-arrow-up">
              <img class="h-7" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
            </button>
          </div>
          @if (idea.fileUrl) {
          <button>
            <a [href]="idea.fileUrl" target="_blank">
              <fa-icon
                class="lg:scale-125 absolute lg:right-2 right-0 bottom-2 flex items-center cursor-pointer"
                data-cy="productidea-link"
                [icon]="faLink"
              ></fa-icon>
            </a>
          </button>
          }
        </div>
      </div>
    </div>

    <div
      [class.active]="showComments[i]"
      class="w-full border-2 border-sunflowerGold comment-section bg-paleOrange"
      data-cy="productidea-comments-list"
      [ngClass]="{
        'border-t-0': !showComments[i],
        'comment-section-mobile': helperService.isPhoneSize$ | async,
      }"
    >
      @for (comment of idea.comments; track comment) {
      <div
        class="bg-paleOrange border-b border-[white] py-2 mx-4 text-xs lg:text-base flex justify-between"
        data-cy="productidea-single-comment"
        [ngClass]="{
          'border-b-0': comment === idea.comments[idea.comments.length - 1]
        }"
      >
        <span class="w-11/12 break-words">{{ comment.comment }}</span>
        @if (comment.commentedBy === authService.getCurrentEmployee()._id) {
        <button
          class="flex pt-1"
          (click)="createRemoveModal(idea, 'comment', comment._id)"
          data-cy="productidea-delete-comment-button"
        >
          <img class="h-3 lg:h-4" src="../../../assets/img/icons/delete.png" alt="delete" />
        </button>
        }
      </div>
      }
      <div class="flex relative items-center bg-paleOrange">
        <textarea
          class="w-full h-10 no-focus resize-none p-2 text-xs lg:text-base"
          data-cy="productidea-comment-textarea"
          (keydown)="enforcePrefix(i)"
          [(ngModel)]="commentPrefix[i]"
        >
        </textarea>
        <button
          class="h-10 w-12 p-1 flex items-center justify-center bg-white"
          (click)="onCreateComment(idea._id, i)"
          data-cy="productidea-send-comment-button"
        >
          <img class="h-4" src="../../../assets/img/icons/send.png" alt="send" />
        </button>
      </div>
    </div>
  </div>
  } @if (showConfirmDialog) {
  <app-confirm-dialog
    (closeDialog)="showConfirmDialog = false"
    (saveChanges)="
      currentOwnCommentId && currentIdeaId
        ? deleteOwnComment(currentIdeaId, currentOwnCommentId)
        : resolveProductIdea(currentProductIdea)
    "
    [data]="modalData"
  ></app-confirm-dialog>
  }
</div>
}
