import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Month } from '../models/common.model';

@Injectable({
  providedIn: 'root',
})
export class RevenueService {
  constructor(private http: HttpClient) {}

  public addNewRevenue(monthEnumValue: Month, revenueTypeEnum, valueOfRevenue: number): Observable<any> {
    return this.http.post(environment.baseUrl + '/revenue', {
      _id: 0,
      month: monthEnumValue,
      year: new Date().getFullYear(),
      type: revenueTypeEnum,
      value: valueOfRevenue,
    });
  }

  public getAllRevenuesCurrentYear(): Observable<any> {
    const currentYear = new Date().getFullYear().toString();
    return this.http.get(environment.baseUrl + `/revenue/all/${currentYear}`);
  }

  public realsAndGoalsRevenuesByQaurter(): Observable<any> {
    const currentYear = new Date().getFullYear().toString();
    return this.http.get(environment.baseUrl + `/revenue/quartersums/${currentYear}`);
  }

  public getAllRevenuesGoalsCurrentYear(): Observable<any> {
    return this.http.get(environment.baseUrl + '/revenue/goal');
  }

  public getAllRevenuesRealCurrentYear(): Observable<any> {
    return this.http.get(environment.baseUrl + '/revenue/real');
  }

  public deleteRevenue(id: string): Observable<any> {
    return this.http.delete(environment.baseUrl + `/revenue/${id}`);
  }
}
