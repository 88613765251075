<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl flex-col">
    <div class="mb-5 flex min-h-[200px] w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box
        id="provision"
        [headline]="'Provision'"
        [headerIcon]="'🤑'"
        class="-mt-20"
      ></app-text-content-box>
    </div>
    <div class="mb-5 flex min-h-[200px] w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box
        id="bonussystem"
        [headline]="'Bonussystem'"
        [headerIcon]="'💰'"
        class="-mt-20"
      ></app-text-content-box>
    </div>
    <div class="mb-5 flex min-h-[200px] w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box id="raises" [headline]="'Raises'" [headerIcon]="'💵'" class="-mt-20"></app-text-content-box>
    </div>
    <div class="mb-5 flex min-h-[200px] w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box
        id="education"
        [headline]="'Education'"
        [headerIcon]="'📚'"
        class="-mt-20"
      ></app-text-content-box>
    </div>
  </div>
</div>
