import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedbackCategory } from '../models/feedback.model';
import { ISortOption } from '../models/sort.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  public getAllFeedbacks(sortOptions: ISortOption, page: number = 0, pageSize: number = 1000): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());
    params = params.set('sortBy', sortOptions.sortBy);
    params = params.set('sortOrder', sortOptions.sortOrder);
    return this.http.get(environment.baseUrl + '/feedback', { params });
  }

  public createFeedback(feedback: string, category: string): Observable<any> {
    return this.http.post(environment.baseUrl + '/feedback', {
      userId: this.authService.getCurrentEmployee()._id,
      text: feedback,
      category: category as FeedbackCategory,
    });
  }

  public voteFeedback(feedbackId: string, vote: number): Observable<any> {
    const userId = this.authService.getCurrentEmployee()._id;
    return this.http.put(environment.baseUrl + '/feedback', {
      id: feedbackId,
      voteCount: vote,
      userid: userId,
    });
  }

  public setSolvedFeedback(feedbackId: string): Observable<any> {
    return this.http.put(environment.baseUrl + `/feedback/solved/${feedbackId}`, {});
  }
}
