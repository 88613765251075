import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private loginState = false;
  private subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.subscription.add(
      this.authService.isLoggedIn().subscribe({
        next: (isLoggedIn: boolean) => {
          this.loginState = isLoggedIn;
        },
        error: (err) => {
          console.error('An error occurred:', err);
        },
      }),
    );
  }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    // trigger jwt validation and then send response
    if (this.loginState === false) {
      // if login state is false, user needs to login
      this.router.navigateByUrl('/login');
    }
    return this.loginState;
  }

  // unsubscribing after leaveing change password page.
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
