import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILeave } from '../models/leave.model';

@Injectable({
  providedIn: 'root',
})
export class LeaveService {
  constructor(private http: HttpClient) {}

  public addLeave(leaveInfo: ILeave): Observable<any> {
    return this.http.post(environment.baseUrl + `/leave`, leaveInfo);
  }

  public deleteLeave(leave: ILeave): Observable<any> {
    return this.http.delete(environment.baseUrl + `/leave/${leave._id}`);
  }

  public getAllLeaves(): Observable<any> {
    return this.http.get(environment.baseUrl + `/leave/all`);
  }

  public getCurrentLeaves(month: number, year: number): Observable<any> {
    const currentMonth = month + 1;
    return this.http.get(environment.baseUrl + `/leave?month=${currentMonth}&year=${year}`);
  }
}
