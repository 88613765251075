import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IConfirmData } from 'src/app/models/confirm-data';
import { FeedbackCategory, IFeedback } from 'src/app/models/feedback.model';
import { FeedbacksResponse } from 'src/app/models/response.model';
import { ISortOption } from 'src/app/models/sort.model';
import { AuthService } from 'src/app/services/auth.service';
import { FeedbackService } from 'src/app/services/feedback.service';

import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.scss'],
})
export class FeedbacksComponent {
  @Input() isHomePage = false;

  private subscription = new Subscription();
  public selectedSortOption: ISortOption = {
    sortBy: 'createdAt',
    sortOrder: 'desc',
  };

  public modalData = {} as IConfirmData;
  public confirmDialog = false;

  public currentFeedback = {} as IFeedback;
  public feedbacks: IFeedback[] = [];

  constructor(
    private authService: AuthService,
    private feedBackService: FeedbackService,
    private toastr: ToastrService,
    private helperService: HelperService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getAllFeedbacks();
    this.subscription.add(
      this.helperService.feedbacks$.subscribe((feedbacks) => {
        this.feedbacks = feedbacks;
        this.getFeedbackCategoryColor();
      }),
    );
  }

  public sortOptionSelected(sortOption: ISortOption): void {
    this.selectedSortOption = sortOption;
    this.getAllFeedbacks();
  }

  private getAllFeedbacks(): void {
    this.subscription.add(
      this.feedBackService.getAllFeedbacks(this.selectedSortOption).subscribe({
        next: (res: FeedbacksResponse): void => {
          this.feedbacks = res.DATA.filter((idea) => !idea.isSolved);
          this.helperService.setFeedbacks([...this.feedbacks]);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public resolveFeedback(feedback: IFeedback): void {
    this.subscription.add(
      this.feedBackService.setSolvedFeedback(feedback._id).subscribe({
        next: () => {
          this.helperService.setFeedbacks(this.feedbacks.filter((f) => f._id !== feedback._id));
          this.toastr.success('Feedback was successfully set to solved');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public getFeedbackCategoryColor(): void {
    this.feedbacks.forEach((feedback) => {
      switch (feedback.category) {
        case FeedbackCategory.OTHER:
          feedback.color = 'red';
          break;
        case FeedbackCategory.SAVING:
          feedback.color = 'green';
          break;
        case FeedbackCategory.IMPROVEMENT:
          feedback.color = 'blue';
          break;
        case FeedbackCategory.FEEDBACK:
          feedback.color = 'orange';
          break;
        case FeedbackCategory.TOOLS:
          feedback.color = 'purple';
          break;
      }
    });
  }

  public voteFeedback(feedback: IFeedback, voteType: 'up' | 'down'): void {
    const employeeId = this.authService.getCurrentEmployee()?._id;
    const feedbackIndex = this.feedbacks.findIndex((item) => feedback._id === item._id);
    const hasUserVoted = feedbackIndex === -1 ? true : this.feedbacks[feedbackIndex].hasVoted.includes(employeeId);
    if (hasUserVoted) {
      this.toastr.error('You have already voted');
    } else {
      const newCount = voteType === 'up' ? feedback.voteCount + 1 : feedback.voteCount - 1;
      this.setVoteAndUpdateFeedbacks(feedback._id, newCount, feedbackIndex);
    }
  }

  private setVoteAndUpdateFeedbacks(id: string, newVoteCount: number, feedbackIndex: number): void {
    this.subscription.add(
      this.feedBackService.voteFeedback(id, newVoteCount).subscribe({
        next: () => {
          this.feedbacks[feedbackIndex].voteCount = newVoteCount;
          this.helperService.setFeedbacks([...this.feedbacks]);
          this.toastr.success('Feedback was successfully voted');
        },
        error: () => {
          this.toastr.error('Failed to vote on feedback');
        },
      }),
    );
  }

  public createRemoveModal(feedback: IFeedback): void {
    this.confirmDialog = true;
    this.currentFeedback = feedback;
    this.modalData.headline = `Delete your feedback?`;
    this.modalData.text = `Are you sure to delete this feedback?`;
    this.modalData.image = 'delete';
  }

  public isShowSolveButton(): boolean {
    return this.authService.getCurrentEmployee().isAdmin && !this.router.url.endsWith('/dashboard');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
