<div class="min-w-screen fixed inset-0 left-0 top-0 z-50 flex h-screen w-full">
  <div class="absolute inset-0 bg-black opacity-30"></div>
  <div
    class="relative m-auto gap-4 bg-lightGrayish p-5 shadow-lg max-lg:max-w-lg lg:w-1/2"
    data-cy="manage-library-dialog"
  >
    <h2 class="pb-4 text-xl font-bold">{{ dialogData.headline }}</h2>

    <div class="flex gap-4 pb-4">
      <input
        [(ngModel)]="booksData.title"
        placeholder="Book Title"
        class="block h-12 w-full p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
        data-cy="book-title"
        type="text"
      />
      <input
        [(ngModel)]="booksData.author"
        placeholder="Author"
        class="block h-12 w-full p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
        data-cy="book-author"
        type="text"
      />
    </div>

    <div class="flex gap-4 pb-4">
      <select
        [(ngModel)]="selectedType"
        (change)="onSelectTypeClick($event)"
        class="block h-12 w-full cursor-pointer p-2.5"
        data-cy="book-type"
      >
        @for (type of getBookTypes(); track type) {
          <option>{{ type }}</option>
        }
      </select>
      <select
        [(ngModel)]="selectedCategory"
        (change)="onSelectCategoryClick($event)"
        class="block h-12 w-full cursor-pointer p-2.5"
        data-cy="book-category"
      >
        @for (category of getBookCategories(); track category) {
          <option>{{ category }}</option>
        }
      </select>
    </div>

    @if (selectedType === Type.eBook) {
      <input
        [(ngModel)]="booksData.url"
        placeholder="Link"
        class="mb-4 block h-12 w-[48%] p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
        data-cy="book-url"
        type="text"
      />
    }

    <div class="flex w-full justify-between text-center" [ngClass]="{ 'pt-4': dialogData?.text }">
      <button
        (click)="closeDialog.emit(false)"
        class="mb-2 flex items-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
      >
        Cancel
      </button>
      <button
        (click)="dialogData.edit ? editBookCard() : createNewBookCard()"
        class="flex h-11 items-center justify-center border border-black bg-almostBlack p-5 text-xs text-sunflowerGold hover:bg-black"
        data-cy="add-book-btn"
      >
        {{ dialogData.edit ? 'Save Book' : 'Add Book' }}
      </button>
    </div>
  </div>
</div>
