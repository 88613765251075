import { Component } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FeedbackCategory } from 'src/app/models/feedback.model';
import { FeedbackResponse } from 'src/app/models/response.model';
import { FeedbackService } from 'src/app/services/feedback.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  private subscription = new Subscription();
  public FeedbackCategory = FeedbackCategory;

  public newFeedback: string;
  public selectedFeedbackCategory: FeedbackCategory;

  constructor(
    private helperService: HelperService,
    private feedbackSerivce: FeedbackService,
    private toastr: ToastrService,
  ) {}

  public onBadgeClick(category: FeedbackCategory) {
    this.selectedFeedbackCategory = category;
  }

  public handleCreateFeedback(): ActiveToast<Error> | void {
    if (!this.selectedFeedbackCategory) {
      return this.toastr.error('Please select one of the options!');
    }
    if (!this.newFeedback.trim()) {
      return this.toastr.error('Text field is empty!');
    }
    this.createNewFeedback();
  }

  private createNewFeedback(): void {
    this.subscription.add(
      this.feedbackSerivce.createFeedback(this.newFeedback.trim(), this.selectedFeedbackCategory).subscribe({
        next: (res: FeedbackResponse) => {
          this.helperService.setFeedbacks([res.DATA, ...this.helperService.feedbacks$.getValue()]);
          this.newFeedback = '';
          this.selectedFeedbackCategory = null;
          this.toastr.success('Feedback was successfully added');
        },
        error: () => {
          this.toastr.error('Failed to create a new feedback');
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
