import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faCommentDots, faLink } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IConfirmData } from 'src/app/models/confirm-data';
import { IProductIdea } from 'src/app/models/productIdea.model';
import { IdeasResponse } from 'src/app/models/response.model';
import { ISortOption } from 'src/app/models/sort.model';
import { AuthService } from 'src/app/services/auth.service';

import { HelperService } from 'src/app/services/helper.service';
import { ProductIdeaService } from 'src/app/services/product-idea.service';

@Component({
  selector: 'app-product-idea-list',
  templateUrl: './product-idea-list.component.html',
  styleUrls: ['./product-idea-list.component.scss'],
})
export class ProductIdeaListComponent {
  public COMMENT_PREFIX = 'Ja, und...';
  public faLink = faLink;
  public commentIcon = faCommentDots;
  public showComments = [false];
  public commentPrefix = [this.COMMENT_PREFIX];

  private subscription = new Subscription();
  public selectedSortOption: ISortOption = {
    sortBy: 'createdAt',
    sortOrder: 'desc',
  };

  public modalData = {} as IConfirmData;
  public showConfirmDialog = false;
  public currentProductIdea: IProductIdea;
  public currentIdeaId: string;
  public currentOwnCommentId: string;
  public productIdeas: IProductIdea[] = [];

  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    public helperService: HelperService,
    private router: Router,
    private productIdeaService: ProductIdeaService,
  ) {}

  ngOnInit(): void {
    this.getAllProductIdeas();
  }

  public sortOptionSelected(sortOption: ISortOption): void {
    this.selectedSortOption = sortOption;
    this.getAllProductIdeas();
  }

  private getAllProductIdeas(): void {
    this.subscription.add(
      this.productIdeaService.getAllIdeas(this.selectedSortOption).subscribe({
        next: (res: IdeasResponse): void => {
          this.productIdeas = res.DATA.filter((idea) => !idea.isSolved);
          this.helperService.setIdeas([...this.productIdeas]);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public upvoteProductIdea(idea: IProductIdea): void {
    const ideaIndex = this.productIdeas.findIndex((item) => idea._id === item._id);
    const employeeId = this.authService.getCurrentEmployee()?._id;
    const hasUserVoted = ideaIndex === -1 ? true : this.productIdeas[ideaIndex].hasVoted.includes(employeeId);
    if (hasUserVoted) {
      this.toastr.error('You have already voted');
    } else {
      const newCount = idea.voteCount + 1;
      this.setVoteAndUpdateIdeas(idea._id, newCount, ideaIndex);
    }
  }

  private setVoteAndUpdateIdeas(id: string, voteCount: number, ideaIndex: number): void {
    this.subscription.add(
      this.productIdeaService.voteIdea(id, voteCount).subscribe({
        next: () => {
          this.productIdeas[ideaIndex].voteCount++;
          this.helperService.setIdeas(this.productIdeas);
          this.toastr.success('Product idea was successfully voted');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public resolveProductIdea(idea: IProductIdea): void {
    this.subscription.add(
      this.productIdeaService.setSolvedIdea(idea._id).subscribe({
        next: () => {
          const ideaIndex = this.productIdeas.findIndex((i) => idea._id === i._id);
          this.productIdeas.splice(ideaIndex, 1);
          this.helperService.setIdeas(this.productIdeas);
          this.toastr.success('Product idea was successfully set to solved');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public onCreateComment(commentId: string, index: number): void {
    const trimmedComment = this.commentPrefix[index].trim();
    const prefix = 'Ja, und...';
    if (!trimmedComment || trimmedComment === prefix || trimmedComment.length <= prefix.length) {
      this.toastr.error('Please enter a comment');
    } else {
      this.createNewComment(commentId, index);
    }
  }

  private createNewComment(commentId: string, index: number): void {
    this.subscription.add(
      this.productIdeaService.createComment(commentId, this.commentPrefix[index]).subscribe({
        next: () => {
          this.getAllProductIdeas();
          this.commentPrefix[index] = 'Ja, und...';
          this.toastr.success('Comment was successfully created');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public deleteOwnComment(ideaId: string, commentId: string): void {
    this.subscription.add(
      this.productIdeaService.deleteOwnComment(ideaId, commentId).subscribe({
        next: () => {
          this.currentIdeaId = null;
          this.currentOwnCommentId = null;
          this.getAllProductIdeas();
          this.toastr.success('Comment was successfully Deleted');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public createRemoveModal(idea: IProductIdea, removeType: 'box' | 'comment', commentId?: string): void {
    this.showConfirmDialog = true;
    if (removeType === 'box') {
      this.currentProductIdea = idea;
      this.modalData.headline = `Delete your product idea?`;
      this.modalData.text = `Are you sure to delete this product idea?`;
      this.modalData.image = 'delete';
    } else {
      this.currentIdeaId = idea._id;
      this.currentOwnCommentId = commentId;
      this.modalData.headline = 'Delete your comment?';
      this.modalData.text = `Are you sure to delete this comment?`;
      this.modalData.image = 'delete';
    }
  }

  public toggleComments(index: number) {
    this.showComments[index] = !this.showComments[index];
    this.commentPrefix[index] = this.COMMENT_PREFIX;
  }

  public enforcePrefix(index: number): void {
    const prefix = this.COMMENT_PREFIX;
    const textWithoutPrefix = this.commentPrefix[index].slice(prefix.length);
    if (!this.commentPrefix[index].startsWith(prefix)) {
      this.commentPrefix[index] = prefix + textWithoutPrefix;
    }
  }

  public showRemoveButton(): boolean {
    return this.authService.getCurrentEmployee().isAdmin && !this.router.url.endsWith('/dashboard');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
