import { Component, Input, OnDestroy } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EClothingSize } from 'src/app/enum/size.enum';
import { EProductType, IProduct } from 'src/app/models/product.model';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrl: './article-card.component.scss',
})
export class ArticleCardComponent implements OnDestroy {
  @Input() public products: IProduct[];
  public EProductType = EProductType;
  public EClothingSize = EClothingSize;

  public showShopDialog = false;
  public selectedProduct: IProduct = null;

  private subscription = new Subscription();

  constructor(
    private transactionService: TransactionService,
    private toastrService: ToastrService,
    private helperService: HelperService,
    private authService: AuthService,
  ) {}

  public onOpenShopDialog(product: IProduct, size?: EClothingSize) {
    this.selectedProduct = product;
    this.selectedProduct.size = size;
    this.showShopDialog = true;
  }

  public closeShopDialog() {
    this.showShopDialog = false;
  }

  public onBuyProduct(product: IProduct) {
    this.subscription.add(
      this.transactionService.createTransaction(product).subscribe({
        next: () => {
          this.toastrService.success('Congrats! Product was added succesfully');

          this.helperService.userCoinsChanged$.next(true);
        },
        error: (err) => {
          this.toastrService.error(err.error.MESSAGE);
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
