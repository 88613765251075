<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl flex-col gap-5">
    <div class="flex flex-col gap-3 bg-lightGrayish p-5 lg:gap-5">
      <app-text-content-box [headline]="'Info'" [headerIcon]="'ℹ️'"></app-text-content-box>
      <p class="text-xs lg:text-base">
        Hier kannst du deiner Kreativität freien Lauf lassen und so viele Produktideen wie möglich abgeben. Bitte
        verfasse deine Idee ruhig auch etwas ausführlicher, damit deine TeamkollegInnen die Idee besser verstehen.
        Danach habt ihr <b>bis zum {{ ideaDeadline }}</b> die Möglichkeit, Produktideen zu bewerten. Chris und Hannes
        wählen dann unter den Top 3 die Gewinner-Idee aus, die von 42 umgesetzt wird. Als IdeengeberIn erhältst du ein
        <b>einmaliges Preisgeld von 200€ und nochmal 2.000€</b>, sobald damit erste Umsätze generiert werden.
      </p>
    </div>
    <div class="flex w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box [headline]="'Submit Idea'" [headerIcon]="'💡'"></app-text-content-box>
      <div class="flex flex-col items-center">
        <div class="relative flex w-full flex-col gap-5 lg:items-end">
          <textarea
            [(ngModel)]="newIdea"
            class="w-full resize-none border-2 border-sunflowerGold bg-paleOrange p-5"
            data-cy="productidea-text-area"
          ></textarea>
          <div class="flex w-full flex-col">
            <span class="w-full text-xs lg:text-base">Link to files and documents (optional):</span>
            <input
              [(ngModel)]="oneDriveLink"
              type="text"
              class="border-2 p-2 text-xs lg:text-base"
              data-cy="productidea-url"
              placeholder="Insert OneDrive link here"
            />
          </div>
          <button
            (click)="onCreateNewIdea()"
            class="min-lg:text-lg flex h-11 items-center justify-center gap-2 border border-black bg-almostBlack p-5 text-sunflowerGold max-lg:text-sm"
            data-cy="submitIdeaButton"
          >
            Submit Idea
            <img class="h-4" src="../../../assets/img/icons/feedback.png" alt="feedback" />
          </button>
        </div>
      </div>
    </div>
    <div class="mb-5 flex flex-col bg-lightGrayish p-5">
      <app-text-content-box [headline]="'Our Ideas'" [headerIcon]="'🤯'"></app-text-content-box>

      <app-product-idea-list class="overflow-auto pr-1"></app-product-idea-list>
    </div>
  </div>
</div>
