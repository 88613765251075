<div class="flex w-full flex-col items-center justify-center">
  <div class="flex w-full max-w-7xl flex-col gap-5">
    <div class="flex flex-col bg-lightGrayish p-5">
      <div class="flex flex-col gap-3 lg:gap-5">
        <app-text-content-box [headline]="'Our Mission'" [headerIcon]="'🎯'"></app-text-content-box>
        <span class="text-xs lg:text-base">
          Unsere Mission ist es, durch individuell auf den Kunden und seine Wünsche zugeschnittene Softwarelösungen neue
          Wege aufzuzeigen. Prozesse sollen optimiert, digitalisiert und automatisiert werden. Neue digitale
          Geschäftsfelder sollen eröffnet werden und auch die Kunden unserer Kunden sollen noch zufriedener werden.
          <br /><br />
          Unser Team aus GeneralistInnen arbeitet unermüdlich daran, fachliches Know-How, neue Technologien sowie
          Branchenkenntnisse zu vereinen, um für unsere Kunden innovative und effiziente Softwarelösungen zu entwickeln.
          <br /><br />
          Dabei setzen wir auf kontinuierliche Weiterbildung, um stets am Puls der Zeit zu bleiben und neueste
          Technologien und Trends in unsere Arbeit einfließen zu lassen.
        </span>
      </div>
    </div>
    <div class="mb-5 flex flex-col bg-lightGrayish p-5">
      <app-text-content-box [headline]="'Our Values'" [headerIcon]="'💎'" class="pb-4 lg:pb-7"></app-text-content-box>
      <app-value-box
        [ourValues]="firstEmployeeValues"
        [width]="'lg:w-[32%]'"
        [position]="'between'"
        [paddingBottom]="true"
        [backgroudColor]="true"
      ></app-value-box>
      <app-value-box
        [ourValues]="secondEmployeeValues"
        [width]="'lg:w-[32%]'"
        [position]="'center'"
        [paddingBottom]="true"
        [backgroudColor]="true"
        [gap]="'gap-[2%]'"
      ></app-value-box>
    </div>
  </div>
</div>
