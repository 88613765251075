<div class="flex items-center">
  @if (headerIcon) {
    <span
      [ngClass]="{ 'max-lg:w-6': headerIcon === 'ℹ️', 'scale-75': headerIcon == '⬆️' }"
      class="flex items-center justify-center text-lg lg:w-7 lg:text-2xl"
    >
      {{ headerIcon }}
    </span>
  }
  <p class="font-bold text-black lg:text-2xl" data-cy="dashb-headline">&nbsp;{{ headline }}</p>
</div>
