import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-navigation-box',
  templateUrl: './navigation-box.component.html',
  styleUrl: './navigation-box.component.scss',
})
export class NavigationBoxComponent {
  @Input() firstText: string;
  @Input() secondText: string;
  @Input() icon: string;
  @Input() link?: string;
}
