import { Component, Input } from '@angular/core';
import { IEmployee } from 'src/app/models/employee.model';
import { ILeave } from 'src/app/models/leave.model';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-user-overview-box',
  templateUrl: './user-overview-box.component.html',
  styleUrl: './user-overview-box.component.scss',
})
export class UserOverviewBoxComponent {
  @Input() public firstText = '';
  @Input() public secondText = '';
  @Input() public subText? = '';
  @Input() public type? = '';
  @Input() public secondType? = '';
  @Input() public thirdType? = '';
  @Input() public nextBirthdayData?: IEmployee[] = [];
  @Input() public mySortedLeaves?: ILeave[] = [];
  constructor(
    public authService: AuthService,
    public helperService: HelperService,
  ) {}
}
