@if (router.url !== '/login') {
  <app-header [userCoinsBalance]="currentUserCoinBalance"></app-header>
}
<div class="relative w-full flex-row">
  @if (!showSidebar && router.url !== '/login') {
    <img
      (click)="helperService.setSidebarVisibility(true)"
      class="absolute top-20 ml-4 h-8 cursor-pointer sm:left-4 sm:h-12"
      src="assets/img/icons/menuIcon.png"
      alt="menuIcon"
    />
  }

  @if (showSidebar && router.url !== '/login') {
    <app-sidebar
      [amountOpenRequests]="amountOpenRequests"
      class="max-lg:absolute max-lg:bottom-0 max-lg:top-0 max-lg:z-50 max-lg:border-r"
    >
    </app-sidebar>
  }

  <div
    [ngClass]="{
      'w-full px-4 pb-16 pt-32 md:px-20': router.url !== '/login',
      'w-full justify-center pt-10': router.url === '/login',
      '!pl-80': showSidebar && !isMobile() && router.url !== '/login',
    }"
  >
    <router-outlet></router-outlet>
  </div>
</div>
