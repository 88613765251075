<a
  [routerLink]="link"
  class="flex flex-col bg-lightGrayish p-4 font-semibold hover:border-[#E6AE48] border border-transparent duration-300 ease-out cursor-pointer h-[5.5rem] lg:h-28 max-lg:text-xs max-lg:items-center"
>
  <span>{{ icon }}</span>
  <span>
    {{ firstText }} <br />
    <span [ngClass]="secondText === 'placeholder' ? 'opacity-0' : 'opacity-100'" class="flex truncate">{{
      secondText
    }}</span>
  </span>
</a>
