import { Pipe, PipeTransform } from '@angular/core';
import { IWorkWeekRequest } from '../models/workWeek.model';

@Pipe({
  name: 'filterByName',
})
export class FilterByNamePipe implements PipeTransform {
  transform(items: IWorkWeekRequest[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    return items.filter((workWeek: IWorkWeekRequest) => {
      const fullName = `${workWeek.employeeId.firstname} ${workWeek.employeeId.lastname}`.toLowerCase();
      return fullName.includes(searchText);
    });
  }
}
