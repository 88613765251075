<div class="flex flex-col w-full max-w-[80rem] lg:h-64 p-6 lg:gap-5 gap-4 bg-lightGrayish">
  <app-text-content-box [headline]="'Revenue Targets'" [headerIcon]="'📈'" class="lg:pb-2"></app-text-content-box>
  <div class="lg:mr-[1.2rem] mr-[0.6rem]">
    <div class="relative">
      <div
        [ngStyle]="{ 'width': progressPercentage + '%', 'max-width': '100%' }"
        [title]="'Real: ' + helperService.sumToFixed(quarters[3].realSum, 'progressBar')"
        class="absolute border-r h-6 z-10 bg-[#FAEED7]"
      ></div>
      <div
        [title]="'Goal: ' + helperService.sumToFixed(quarters[3].goalSum, 'progressBar')"
        class="w-full h-6 bg-white"
      ></div>
    </div>
  </div>
  <div class="flex w-full text-[10px] lg:text-lg z-20 font-semibold pl-4">
    @for (quarter of quarters; track quarter) {
      <div class="flex w-full justify-center text-veryFaintBlack">{{ quarter.label }}</div>
      <div class="flex flex-col items-center">
        <div class="h-12 -mt-11 w-1/2 border-r-2 border-r-black"></div>
        <p>{{ helperService.sumToFixed(quarter.realSum, 'progressBar') }}</p>
        <p class="text-veryFaintBlack">{{ helperService.sumToFixed(quarter.goalSum, 'progressBar') }}</p>
      </div>
    }
  </div>
</div>
