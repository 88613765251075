import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBook } from '../models/book.model';
import { ISuggestion } from '../models/suggestion.model';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  constructor(private http: HttpClient) {}

  public getAllBooks(): Observable<any> {
    return this.http.get(environment.baseUrl + '/library/books');
  }

  public createBook(book: IBook): Observable<any> {
    return this.http.post(environment.baseUrl + '/library/book', book);
  }

  public updateBook(book: IBook, newRequest: boolean): Observable<any> {
    const updateData = {
      book: book,
      newRequest: newRequest,
    };
    return this.http.put(environment.baseUrl + `/library/book/${book._id}`, updateData);
  }

  public deleteBook(bookId: string): Observable<any> {
    return this.http.delete(environment.baseUrl + `/library/book/${bookId}`);
  }

  public getAllSuggestions(): Observable<any> {
    return this.http.get(environment.baseUrl + '/library/suggestions');
  }

  public createSuggestion(suggestion: ISuggestion): Observable<any> {
    return this.http.post(environment.baseUrl + '/library/suggestion', suggestion);
  }

  public updateSuggestion(suggestion: ISuggestion): Observable<any> {
    return this.http.put(environment.baseUrl + `/library/suggestions/${suggestion._id}`, suggestion);
  }

  public voteSuggestion(suggestionId: string, vote: number): Observable<any> {
    return this.http.put(environment.baseUrl + '/library/suggestion/vote/' + suggestionId, {
      upvotes: vote,
    });
  }

  public deleteSuggestion(suggestionId: string, isBought: 'true' | 'false'): Observable<any> {
    let params = new HttpParams().set('isBought', isBought);
    return this.http.delete(environment.baseUrl + `/library/suggestion/` + suggestionId, { params: params });
  }
}
