import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, map, Observable, Subscription } from 'rxjs';
import { IConfirmData } from 'src/app/models/confirm-data';
import { IEmployee } from 'src/app/models/employee.model';
import { WorkWeekRequestsResponse } from 'src/app/models/response.model';
import { IWorkWeekRequest } from 'src/app/models/workWeek.model';
import { FilterByNamePipe } from 'src/app/pipes/filter-by-name.pipe';
import { WorkweekService } from 'src/app/services/workweek.service';

@Component({
  selector: 'app-working-time',
  templateUrl: './working-time.component.html',
  styleUrl: './working-time.component.scss',
})
export class WorkingTimeComponent implements OnInit, OnDestroy {
  @Output() employeeUpdate = new EventEmitter<IEmployee>();
  @Output() fetchEmployees = new EventEmitter();
  @Input() employees: IEmployee[];

  private subscriptions = new Subscription();

  private weekOffset = 0;
  private firstDayOfWeek: Date;

  public workWeeks = [] as IWorkWeekRequest[];

  public searchEmployee: string;
  public currentWeek: string;

  public dataToConfirm: IConfirmData = {} as IConfirmData;
  public confirmDialog = false;
  public showArchivedEmployees = false;

  constructor(
    private toastr: ToastrService,
    public filterByNamePipe: FilterByNamePipe,
    private workweekService: WorkweekService,
  ) {}

  ngOnInit(): void {
    this.currentWeek = this.getWeekRange(new Date());
    this.fetchWorkWeeks(this.firstDayOfWeek.toISOString().split('T')[0]);
  }

  public fetchWorkWeeks(startDay: string): void {
    this.subscriptions.add(
      this.workweekService.getWorkWeeks(startDay).subscribe({
        next: (res: WorkWeekRequestsResponse) => {
          const workWeeks = res.DATA as IWorkWeekRequest[];
          if (workWeeks.length) {
            this.workWeeks = workWeeks;
          } else this.createDefaultWorkWeeks();
        },
        error: () => {
          this.toastr.error('Working weeks could not be loaded');
        },
      }),
    );
  }

  public fetchWorktimeInWeekForAllUsers(): void {
    if (this.employees.length === 0) {
      return;
    }
    const startDay = this.firstDayOfWeek.toISOString();

    const worktimeRequests = this.getWorktimeRequestsForActiveEmployees(startDay);
    if (worktimeRequests.length === 0) {
      return;
    }

    this.subscriptions.add(
      forkJoin(worktimeRequests).subscribe({
        next: (results) => {
          results?.forEach((result) => {
            const usersWorkweek = this.workWeeks.find((workWeek) => workWeek.employeeId._id === result.employeeId);
            if (usersWorkweek) {
              usersWorkweek.minutesWorkedClockify = result.DATA.minutesWorked;
            }
          });
        },
        error: (err) => {
          console.error('Error occurred: ', err);
          this.toastr.error('Working time from clockify could not be loaded');
        },
      }),
    );
  }

  getWorktimeRequestsForActiveEmployees(startDay: string): Observable<{
    employeeId: string;
    DATA: {
      minutesWorked: number;
    };
  }>[] {
    const activeEmployyes = this.employees.filter((employee) => !employee.isArchived);
    return activeEmployyes.map((employee) =>
      this.workweekService.getWorktimeMinutesInWeekForUser(startDay, employee._id).pipe(
        map((res) => {
          return {
            employeeId: employee._id,
            DATA: res.DATA,
          };
        }),
      ),
    );
  }

  public createDefaultWorkWeeks(): void {
    this.workWeeks = [] as IWorkWeekRequest[];
    this.employees?.forEach((employee) => {
      const workWeekRequest: IWorkWeekRequest = {
        employeeId: {
          _id: employee._id,
          firstname: employee.firstname,
          lastname: employee.lastname,
          weeklyHours: employee.weeklyHours,
          overtimeBalance: employee.overtimeBalance,
          isArchived: employee.isArchived,
        },
        startDate: this.firstDayOfWeek,
        minutesWorked: 0,
        minutesWorkedClockify: undefined,
      };
      this.workWeeks.push(workWeekRequest);
    });
  }

  private getWeekRange(date: Date): string {
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    this.firstDayOfWeek = new Date(date.setDate(diff));
    const lastDayOfWeek = new Date(date.setDate(diff + 6));
    return `${this.firstDayOfWeek.toLocaleDateString('de-DE')} - ${lastDayOfWeek.toLocaleDateString('de-DE')}`;
  }

  public saveWorkWeeks(): void {
    /*     this.workWeeks.forEach((week) => {
      const employee = this.employees.find((e) => e._id === week.employeeId._id);
      employee.overtimeBalance = week.employeeId.overtimeBalance;
    }); */
    if (this.workWeeks[0]._id) this.updateWorkWeeks(this.workWeeks);
    else this.createWorkWeeks(this.workWeeks);
  }

  public getWorkWeeks() {
    return this.showArchivedEmployees
      ? this.workWeeks.filter((workWeek) => workWeek.employeeId.isArchived)
      : this.workWeeks.filter((workWeek) => !workWeek.employeeId.isArchived);
  }

  private createWorkWeeks(workWeeksToCreate: IWorkWeekRequest[]): void {
    this.subscriptions.add(
      this.workweekService.createWorkWeeks(workWeeksToCreate).subscribe({
        next: (res: WorkWeekRequestsResponse) => {
          this.workWeeks = res.DATA;
          this.toastr.success(`Working hours for the week ${this.currentWeek} successfully saved`);
          this.fetchEmployees.emit();
        },
        error: () => {
          this.toastr.error('Working time could not be saved');
        },
      }),
    );
  }

  private updateWorkWeeks(workWeeksToUpdate: IWorkWeekRequest[]): void {
    this.subscriptions.add(
      this.workweekService
        .updateWorkWeek(workWeeksToUpdate, new Date(this.firstDayOfWeek).toISOString().split('T')[0])
        .subscribe({
          next: (res: WorkWeekRequestsResponse) => {
            this.workWeeks = res.DATA;
            this.toastr.success(`Working hours for the week ${this.currentWeek} successfully updated`);
            this.fetchEmployees.emit();
          },
          error: () => {
            this.toastr.error('Working time could not be updated');
          },
        }),
    );
  }

  public onChangeWeek(option: 'last' | 'next'): void {
    this.weekOffset += option === 'next' ? 1 : -1;
    this.currentWeek = this.getWeekRange(new Date(Date.now() + this.weekOffset * 7 * 24 * 60 * 60 * 1000));
    this.fetchWorkWeeks(new Date(this.firstDayOfWeek).toISOString().split('T')[0]);
  }

  public updateEmployee(updatedEmployee: IEmployee): void {
    const workWeekOfUpdatedEmployee = this.workWeeks.find(
      (workWeek) => workWeek.employeeId._id === updatedEmployee._id,
    );
    workWeekOfUpdatedEmployee.employeeId.overtimeBalance = updatedEmployee.overtimeBalance;
    this.employeeUpdate.emit(updatedEmployee);
  }

  public openConfirmDialog(): void {
    this.dataToConfirm.headline = 'Enter working hours ?';
    this.dataToConfirm.text = `Are you sure you want to enter the hours for the week ${this.currentWeek}?`;
    this.dataToConfirm.image = 'users';
    this.confirmDialog = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
