<div
  class="min-w-screen animated fadeIn faster fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat outline-none focus:outline-none"
  id="modal-id"
>
  <div class="absolute inset-0 z-0 bg-black opacity-30"></div>
  <div class="relative m-auto w-full max-w-lg rounded-xl bg-lightGrayish p-5 shadow-lg">
    <div class="flex flex-col items-center p-5 text-center" data-cy="confirm-dialog">
      @if (data.image) {
        <img class="h-10" src="assets/img/icons/{{ data.image }}.png" alt="delete" />
      }
      <h2 class="py-4 text-xl">{{ data.headline }}</h2>
      <p class="px-8 text-sm text-slateGray">{{ data.text }}</p>
      <div class="flex w-full items-center justify-between">
        <div class="mt-2 flex items-center space-x-4 p-3 text-center">
          <button
            (click)="onAccept()"
            class="mb-2 flex h-9 items-center justify-center border border-black bg-almostBlack px-5 py-2.5 text-center text-sm font-medium tracking-wider text-sunflowerGold shadow-sm hover:bg-black hover:shadow-lg focus:outline-none focus:ring-4 sm:w-auto md:mb-0"
            data-cy="modal-confirm-button"
          >
            {{ onAcceptButtonText }}
          </button>
        </div>
        <div class="mt-2 flex justify-center space-x-4 p-3 text-center">
          <button
            (click)="onClose()"
            class="text-slateGray mb-2 flex h-9 items-center border bg-white px-5 py-2 text-sm font-medium tracking-wider shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
            data-cy="pi-reject-del-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
