import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-content-box',
  templateUrl: './text-content-box.component.html',
  styleUrl: './text-content-box.component.scss',
})
export class TextContentBoxComponent {
  @Input() headerIcon: any;
  @Input() headline: string;
}
