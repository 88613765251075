import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoalsService {
  constructor(private http: HttpClient) {}

  public createNewCompanyGoal(goalText: string): Observable<any> {
    return this.http.post(environment.baseUrl + '/goal', {
      description: goalText,
    });
  }

  public getAllCompanyGoals(): Observable<any> {
    return this.http.get(environment.baseUrl + '/goal/all');
  }

  public deleteCompanyGoal(goalId: string): Observable<any> {
    return this.http.delete(environment.baseUrl + `/goal/${goalId}`);
  }
}
