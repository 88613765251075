import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProductIdea } from '../models/productIdea.model';
import { ISortOption } from '../models/sort.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProductIdeaService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  public createIdea(idea: IProductIdea, fileUrl?: string): Observable<any> {
    return this.http.post(environment.baseUrl + '/productidea', {
      text: idea,
      fileUrl: fileUrl,
    });
  }

  public getAllIdeas(sortOptions: ISortOption, page: number = 0, pageSize: number = 1000): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());
    params = params.set('sortBy', sortOptions.sortBy);
    params = params.set('sortOrder', sortOptions.sortOrder);
    return this.http.get(environment.baseUrl + '/productidea', { params });
  }

  public voteIdea(ideaId: string, vote: number): Observable<any> {
    const userId = this.authService.getCurrentEmployee()._id;
    return this.http.put(environment.baseUrl + '/productidea', {
      id: ideaId,
      voteCount: vote,
      userid: userId,
    });
  }

  public setSolvedIdea(ideaId: string): Observable<any> {
    return this.http.put(environment.baseUrl + `/productidea/solved/${ideaId}`, {});
  }

  public createComment(ideaId: string, comment: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/productidea/comment/${ideaId}`, {
      comment: comment,
    });
  }

  public deleteOwnComment(ideaId: string, commentId: string): Observable<any> {
    const body = {
      body: {
        _id: commentId,
      },
    };
    return this.http.delete(`${environment.baseUrl}/productidea/comment/${ideaId}`, body);
  }
}
