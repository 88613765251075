<div class="flex w-full flex-col items-center justify-center">
  <div class="w-full max-w-7xl">
    <div class="flex flex-col gap-[15px] bg-lightGrayish p-5 lg:gap-5">
      <app-text-content-box [headline]="'Holiday Planning'" [headerIcon]="'📅'"></app-text-content-box>
      <div class="flex w-full flex-col-reverse gap-5 text-sm lg:flex-row xl:text-base">
        <div class="flex w-full max-w-full flex-col items-start justify-between gap-5 lg:w-1/2">
          <div
            class="flex flex-col justify-between gap-5 font-semibold text-veryFaintBlack lg:w-full lg:flex-row lg:items-center lg:gap-0"
          >
            <div class="flex">
              <p class="border-r-2 pr-2">Holiday Entitlement</p>
              <p class="pl-2 text-black">{{ getCurrentEmployee().vacationDays }} Days</p>
            </div>
            <div class="flex">
              <p class="border-r-2 pr-2">Overtime</p>
              <p class="pl-2 text-black">{{ getOvertimeBalance() }} Hours</p>
            </div>
          </div>
          <div class="flex flex-wrap gap-4 sm:flex-row">
            <button
              (click)="openClockifyVacationPlanning()"
              type="button"
              class="mainButton"
              class="flex h-11 w-[180px] items-center justify-evenly border border-black bg-almostBlack text-sm text-sunflowerGold"
            >
              Vacation Planning
              <img class="h-3" src="../../../assets/img/icons/vacation1.svg" alt="calendar" />
            </button>

            <button
              (click)="openAddLeaveDialog()"
              type="button"
              class="mainButton"
              class="flex h-11 w-[180px] items-center justify-evenly border border-black bg-almostBlack text-sm text-sunflowerGold"
            >
              Add Overtime Leave
              <img class="h-3" src="../../../assets/img/icons/calendar.png" alt="calendar" />
            </button>
          </div>
        </div>
        <div class="flex w-full overflow-auto lg:w-auto min_w_1640:w-[80%]">
          <table class="mb-3 max-xl:text-sm sm:mb-0">
            <thead>
              <tr>
                <th class="font-semibold">Employee</th>
                <th class="font-semibold">Start</th>
                <th class="font-semibold">End</th>
                <th class="font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody class="bg-white font-medium">
              @for (leave of myLeaves; track leave) {
                <tr>
                  <td>
                    {{ getCurrentEmployee().firstname }}
                    {{ getCurrentEmployee().lastname }}
                  </td>
                  <td class="h-max-[44px] w-[136px]">
                    {{ leave.startDate ? (leave.startDate | date: 'dd.MM' : 'de') : '' }}
                  </td>
                  <td class="h-max-[44px] w-[136px]">
                    {{ leave.startDate ? (leave.endDate | date: 'dd.MM' : 'de') : '' }}
                  </td>
                  <td class="h-max-[44px] w-[136px]">
                    <div class="flex justify-around">
                      <button (click)="openEditLeaveDialog(leave)">
                        <img class="h-4 sm:h-5" src="assets/img/icons/edit.png" alt="edit" />
                      </button>
                      <button (click)="openConfirmDeleteDialog(leave)">
                        <img class="h-4 sm:h-5" src="assets/img/icons/delete.png" alt="delete" />
                      </button>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
            @if (myLeaves?.length === 0) {
              <p class="mt-2 whitespace-nowrap">There are no leaves in calendar</p>
            }
          </table>
        </div>
      </div>
      <div class="flex">
        @if (!isLoading) {
          <div
            class="flex w-full flex-col gap-1 overflow-auto scroll-smooth"
            id="calendar"
            (mouseenter)="onCalendarMouseEnter()"
            (mouseleave)="onCalendarMouseLeave()"
          >
            <div class="flex items-center gap-1 pb-4">
              <img
                (click)="switchToPreviousMonth()"
                class="h-4 cursor-pointer text-lg"
                src="assets/img/icons/arrow-left.png"
                alt="arrow-left"
              />
              <p class="text-xl font-medium">{{ monthNames[month] }}</p>
              <img
                (click)="switchToNextMonth()"
                class="h-4 rotate-180 cursor-pointer text-lg"
                src="assets/img/icons/arrow-left.png"
                alt="arrow-left"
              />
            </div>
            @for (employee of employees; track employee; let row = $index) {
              <div id="{{ row }}" class="mb_10 flex w-full items-center">
                @if (!employee.isArchived) {
                  <div class="flex w-full items-center">
                    <div
                      class="flex h-[69px] min-w-[13rem] flex-col items-center justify-center gap-1 bg-black pl-2 pr-2 text-left text-white sm:w-full sm:flex-row"
                    >
                      <p class="overflow-hidden text-ellipsis">{{ employee?.firstname }}</p>
                      <p>{{ employee?.lastname }}</p>
                    </div>
                    @for (date of datesOfCurrentMonth; track date; let column = $index) {
                      <div
                        class="flex h-[69px] bg-white"
                        [ngClass]="{
                          gap_event: date.dayOfWeek === 6,
                        }"
                      >
                        @if (date.dayOfWeek !== 0 && date.dayOfWeek !== 6) {
                          <div
                            class="flex w-11 cursor-pointer justify-center border"
                            (click)="detectLeaveEvent(row, date)"
                            [ngClass]="{
                              today: isToday(date),
                              events: isLeave(row, column, 1),
                              overtime: isLeave(row, column, 2),
                            }"
                          >
                            <div class="flex items-center">
                              <div class="text-center text-sm hover:text-gray-800">
                                <p>{{ date.weekday.substring(0, 2) }}</p>
                                <div class="w-full px-2">
                                  <p class="mt-3">
                                    {{ date.date.slice(-2) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
        @if (isLeaveDialogOpen) {
          <app-leave-dialog
            (addLeaveData)="proofAndTransferLeave($event, 'add')"
            (editLeaveData)="proofAndTransferLeave($event, 'edit')"
            (closeDialog)="closeLeaveDialog()"
            (deleteLeave)="proofAndTransferLeave($event, 'delete')"
            [employees]="employees"
            [allLeaves]="allLeaves"
            [info]="leaveDialog"
            [selectedLeave]="leaveData"
          ></app-leave-dialog>
        }
        @if (isConfirmDialogOpen) {
          <app-confirm-dialog
            (deleteLeave)="proofAndTransferLeave($event, 'delete')"
            (closeDialog)="closeConfirmDialog()"
            [data]="dataToConfirm"
          ></app-confirm-dialog>
        }
      </div>
    </div>
  </div>
</div>
