import { Component, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-value-box',
  templateUrl: './value-box.component.html',
  styleUrl: './value-box.component.scss',
})
export class ValueBoxComponent {
  @Input() ourValues: any;
  @Input() width?: string;
  @Input() position: string;
  @Input() gap?: string;
  @Input() paddingBottom: boolean;
  @Input() backgroudColor: boolean;

  public hovering: boolean[] = [];

  ngOnInit() {
    this.hovering = Array(this.ourValues.length).fill(false);
  }
}
