<div class="flex justify-center items-center gap-1 rounded p-[2px] text-white relative">
  <div
    [ngClass]="{
      'bg-[#333333]': selectedOption.sortBy === 'createdAt',
      'bg-gray-300': selectedOption.sortBy !== 'createdAt'
    }"
    (click)="selectOption('createdAt')"
    class="px-2 py-1 rounded text-center sm:text-sm text-xs w-24 sm:w-36 transition-all duration-300 cursor-pointer"
    data-cy="sort-latest"
  >
    <span>Latest</span>
  </div>
  <div
    [ngClass]="{
      'bg-[#333333]': selectedOption.sortBy === 'voteCount',
      'bg-gray-300': selectedOption.sortBy !== 'voteCount'
    }"
    (click)="selectOption('voteCount')"
    class="px-2 py-1 rounded text-center sm:text-sm text-xs w-24 sm:w-36 transition-all duration-300 cursor-pointer"
    data-cy="sort-most-popular"
  >
    <span>Most Popular</span>
  </div>
</div>
