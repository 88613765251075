<td class="truncate border px-4 py-2" data-cy="workweek-employee-name">
  {{ workWeek.employeeId?.firstname }} {{ workWeek.employeeId?.lastname }}
</td>
<td class="truncate border px-4 py-2" data-cy="workweek-overtime-total">
  {{ helperService.formatMinutes(workWeek.employeeId.overtimeBalance) }}
</td>
<td class="border px-4 py-2" data-cy="workweek-overtime-week">
  <div class="flex w-12 justify-end">{{ getOvertime(workWeek.employeeId.weeklyHours) }}</div>
</td>
<td class="border px-4 py-2" data-cy="workweek-working-time">
  <div class="flex w-6 justify-end">{{ workWeek.employeeId.weeklyHours || 0 }}</div>
</td>

<td class="relative w-28 !border-0 p-0">
  <form [formGroup]="timeForm">
    <input
      formControlName="timeInput"
      (input)="helperService.onTimeInput($event, timeForm)"
      type="text"
      placeholder="HH:MM"
      [ngClass]="{
        'bg-red-50':
          workWeek.minutesWorked / 60 >= workWeek.employeeId.weeklyHours + 10 || workWeek.employeeId.isArchived,
        'bg-red-300': workWeek.minutesWorkedClockify >= 0 && workWeek.minutesWorked !== workWeek.minutesWorkedClockify,
        'bg-green-300':
          workWeek.minutesWorkedClockify >= 0 && workWeek.minutesWorked === workWeek.minutesWorkedClockify,
      }"
      class="flex h-full w-full border border-gray-300 p-2.5 px-4 py-2 placeholder-veryTransparentBlack placeholder:font-semibold"
      data-cy="workweek-actual"
    />
  </form>
  <p
    class="absolute right-0.5 top-0.5 text-xs"
    [ngClass]="{
      'hidden': workWeek.employeeId.isArchived || workWeek.minutesWorkedClockify === undefined,
      'text-red-500':
        workWeek.minutesWorked / 60 >= workWeek.employeeId.weeklyHours + 10 || workWeek.employeeId.isArchived,
      'text-red-800': workWeek.minutesWorkedClockify >= 0 && workWeek.minutesWorked !== workWeek.minutesWorkedClockify,
      'text-green-800':
        workWeek.minutesWorkedClockify >= 0 && workWeek.minutesWorked === workWeek.minutesWorkedClockify,
    }"
  >
    {{ helperService.formatMinutes(this.workWeek.minutesWorkedClockify) }}
  </p>
</td>
