import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
@Component({
  selector: 'app-mission-values',
  templateUrl: './mission-values.component.html',
  styleUrls: ['./mission-values.component.scss'],
})
export class MissionValuesComponent {
  firstEmployeeValues = [
    {
      title: 'Like the earth is circling the sun, we revolve around our customers.',
      description:
        'Wir produzieren nichts, wir stellen nichts her, aber wir kreieren Mehrwert für unsere Kunden. Sie stehen im Zentrum und wir richten uns nach ihren Wünschen, ohne Unmögliches zu versprechen. Happy client, happy life!',

      imgSrc: 'assets/img/svg/solar',
      imgAlt: 'solar',
    },
    {
      title: 'Do good work. Create value.',
      description:
        'Wir sind gut in dem, was wir tun, und das sehen Kunden tagtäglich an der Software, die wir für sie erstellen. Unsere Software ist Mehrweg-Code und soll Mehrwert schaffen. Nachhaltigkeit statt Mittelmäßigkeit.',

      imgSrc: 'assets/img/svg/good',
      imgAlt: 'good',
    },
    {
      title: 'Without a goal you cannot score.',
      description:
        'Wir arbeiten immer auf ein gemeinsames Ziel hin. Wir gewinnen täglich Spiele. Kannst du es vor lauter Alltag nicht sehen, schlage Alarm. Frage nach. Stehst du nicht dahinter, sei kritisch und mutig.',
      imgSrc: 'assets/img/svg/goal',
      imgAlt: 'goal',
    },
  ];

  secondEmployeeValues = [
    {
      title: 'Failures are part of your job, but chances to grow, as well.',
      description:
        '  Fehlerkultur und so. Nein, im Ernst… es ist nicht schlimm, Fehler zu machen. Jeder macht sie. Nutze sie als Chance, besser zu werden. Hilf deinem Team, nicht dieselben Fehler zu machen.',
      imgSrc: 'assets/img/svg/grow',
      imgAlt: 'grow',
    },
    {
      title: 'Please don’t hudel (hurry).',
      description:
        ' Es kann manchmal stressig sein, Deadlines stehen an oder der Kunde will gestern sein Projekt abschließen. Aber wie wir in Bayern sagen: Bloß ned hudeln! Gewissenhaftigkeit geht vor Geschwindigkeit. Das spart Geld, Nerven und „we do good work“.',
      imgSrc: 'assets/img/svg/hudel',
      imgAlt: 'balance_black',
    },
  ];
}
