import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IWorkWeekRequest } from '../models/workWeek.model';
import { MinutesWorkedRequestsResponse } from '../models/response.model';
@Injectable({
  providedIn: 'root',
})
export class WorkweekService {
  constructor(private http: HttpClient) {}

  public getWorkWeeks(startDate: string): Observable<any> {
    return this.http.get(environment.baseUrl + `/workweeks?startDate=${startDate}`);
  }

  public getWorktimeMinutesInWeekForUser(
    startDate: string,
    employeeId: string,
  ): Observable<MinutesWorkedRequestsResponse> {
    return this.http.get<MinutesWorkedRequestsResponse>(
      environment.baseUrl + `/workweeks/clockify?employeeId=${employeeId}&startDate=${startDate}`,
    );
  }

  public createWorkWeeks(workWeeks: IWorkWeekRequest[]): Observable<any> {
    return this.http.post(environment.baseUrl + `/workweeks`, workWeeks);
  }

  public updateWorkWeek(workWeek: IWorkWeekRequest[], startDate: string): Observable<any> {
    const params = new HttpParams().set('startDate', startDate);
    return this.http.put(environment.baseUrl + `/workweeks`, workWeek, { params });
  }
}
