import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DialogType } from 'src/app/enum/dialog-type.enum';
import { IEmployee } from 'src/app/models/employee.model';
import { HistoryDialogData, ICoinTransferHistory, IOvertimeBalanceHistory } from 'src/app/models/history-dialog.model';
import { HelperService } from 'src/app/services/helper.service';
import { HistoryService } from 'src/app/services/history.service';

@Component({
  selector: 'app-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrl: './history-dialog.component.scss',
})
export class HistoryDialogComponent implements OnInit {
  @Input() data: HistoryDialogData;
  @Input() employees: IEmployee[];
  @Output() closeDialog = new EventEmitter<boolean>();
  private subscriptions = new Subscription();

  public coinTransferHistory: ICoinTransferHistory[] = [];
  public overtimeBalanceHistory: IOvertimeBalanceHistory[] = [];
  public DialogType = DialogType;

  constructor(
    private toastrService: ToastrService,
    private historyService: HistoryService,
    private helperService: HelperService,
  ) {}

  ngOnInit(): void {
    if (this.data.type === DialogType.OVERTIME) {
      this.getOvertimeHistory();
    } else if (this.data.type === DialogType.COINBALANCE) {
      this.getCoinBalanceHistory();
    } else {
      this.toastrService.error('Unknown Dialog Type');
    }
  }
  private getOvertimeHistory(): void {
    this.subscriptions.add(
      this.historyService.getOvertimeHistoryOfEmployee(this.data.employee).subscribe({
        next: (res) => {
          this.overtimeBalanceHistory = res.DATA;
        },
        error: () => {
          this.toastrService.error('An error occured fetching the overtime history');
        },
      }),
    );
  }

  private getCoinBalanceHistory(): void {
    this.subscriptions.add(
      this.historyService.getCoinBalanceHistoryOfEmployee(this.data.employee).subscribe({
        next: (res) => {
          this.coinTransferHistory = res.DATA;
        },
        error: () => {
          this.toastrService.error('An error occured fetching the coin balance history');
        },
      }),
    );
  }

  public getHoursFromMinutes(minutes: number): string {
    return this.helperService.formatMinutes(minutes);
  }

  public getEmployee(employeeId: string): string {
    if (employeeId === 'system') {
      return 'System';
    }
    const employee = this.employees.find((employee) => employee._id === employeeId);
    if (!employee) {
      return 'unknown';
    }

    return employee.firstname + ' ' + employee.lastname.charAt(0) + '.';
  }

  public onClose(): void {
    this.closeDialog.emit(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
