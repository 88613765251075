import { Component, Input } from '@angular/core';
import { faLock, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { EmployeeRole } from 'src/app/enum/employeeRole.enum';
import { IEmployee } from 'src/app/models/employee.model';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() public userCoinsBalance;

  public employee = {} as IEmployee;

  public passwordDialog = false;

  public logoutIcon = faRightFromBracket;
  public password = faLock;

  public showSidebar = false;
  public isPhoneSize: boolean;
  public EmployeeRole = EmployeeRole;

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
  ) {
    this.employee = this.authService.getCurrentEmployee();
  }

  ngOnInit() {
    this.observeSidebarVisibility();
    this.observePhoneSize();
  }

  observeSidebarVisibility(): void {
    this.helperService.showSidebar$.subscribe((visible) => {
      this.showSidebar = visible;
    });
  }

  observePhoneSize(): void {
    this.helperService.isPhoneSize$.subscribe((isPhoneSize) => {
      this.isPhoneSize = isPhoneSize;
    });
  }

  public logout(): void {
    this.authService.logout();
  }
}
