import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IEmployee } from 'src/app/models/employee.model';
import { ETrainingState, ETrainingType, ITraining } from 'src/app/models/training.model';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { TrainingService } from 'src/app/services/training.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrl: './training.component.scss',
})
export class TrainingComponent {
  private subscriptions: Subscription = new Subscription();
  public training = {} as ITraining;
  public currentEmployee: IEmployee;
  public ETrainingState = ETrainingState;
  public showPastRequests = true;

  public timeForm: FormGroup;
  public employeeTrainings: ITraining[];

  constructor(
    public helperService: HelperService,
    private authService: AuthService,
    private trainingService: TrainingService,
    private toastService: ToastrService,
    private fb: FormBuilder,
  ) {
    this.currentEmployee = authService.getCurrentEmployee() as IEmployee;
    this.training.employeeId = this.currentEmployee._id;
    this.getEmployeeTrainings();
  }

  ngOnInit(): void {
    this.timeForm = this.fb.group({
      timeInput: ['', [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3])(:[0-5][0-9])?$/)]],
    });
  }

  public onTrainingDateSelect(event: Event): void {
    this.training.trainingDate = this.helperService.dateToISOString(event);
  }

  private getEmployeeTrainings(showPastRequests = true) {
    this.subscriptions.add(
      this.trainingService.getTrainingsOfEmployee(this.currentEmployee._id, showPastRequests).subscribe({
        next: (res) => {
          this.employeeTrainings = res.DATA;
        },
        error: () => {
          this.toastService.error('An error occurred fetching the trainings');
        },
      }),
    );
  }

  public toggleOpenTrainings(): void {
    this.showPastRequests = !this.showPastRequests;
    this.getEmployeeTrainings(this.showPastRequests);
  }

  public getTrainingDate(training: ITraining): Date {
    return new Date(training.trainingDate);
  }

  public getTrainingDuration(trainingDurationInMinutes: number): string {
    return this.helperService.formatMinutes(trainingDurationInMinutes);
  }

  public onAddWorktime() {
    this.training.duration = this.helperService.calculateMinutes(this.timeForm.get('timeInput').value);
    this.training.type = ETrainingType.EXTERNAL;
    /*     const trainingDate = this.training.trainingDate.toISOString();
    this.training.trainingDate = trainingDate; */
    this.subscriptions.add(
      this.trainingService.createTraining(this.training).subscribe({
        next: () => {
          this.toastService.success('Training was successfully created');
          this.training = {
            employeeId: this.currentEmployee._id,
          } as ITraining;
          this.timeForm.get('timeInput').reset();
          this.getEmployeeTrainings(this.showPastRequests);
        },
        error: (err) => {
          this.toastService.error(err);
        },
      }),
    );
  }

  public timeInputHasValue(): boolean {
    return !!this.timeForm.get('timeInput').value;
  }
}
