import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProduct } from '../models/product.model';
import { EState, ITransaction } from '../models/transaction.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private http: HttpClient) {}

  public createTransaction(product: IProduct): Observable<any> {
    return this.http.post(environment.baseUrl + '/transaction', { product });
  }

  public getLatestTransactions(): Observable<any> {
    return this.http.get(environment.baseUrl + '/transactions/latest');
  }

  public getAllProducts(): Observable<any> {
    return this.http.get(environment.baseUrl + '/products/all');
  }

  public getMyCoins(): Observable<any> {
    return this.http.get(environment.baseUrl + '/employee/coins');
  }

  public getAllTransactions(getRequested: boolean): Observable<any> {
    return this.http.get(environment.baseUrl + `/transactions/requested?getRequested=${getRequested}`);
  }

  public getAllTransactionsAmount(): Observable<any> {
    return this.http.get(environment.baseUrl + '/transactions/amount-requested');
  }
  //expects the transaction :id and a query parameter status (1 = Approved, 2 = Cancelled)
  /*       app.put('/api/transactions/changestatus/:id', (req: Request, res: Response) => {
        this.transactionController.changeTransactionStatus(req, res);
      }); */

  public setTransactionStatus(transaction: ITransaction, state: EState) {
    return this.http.put(
      environment.baseUrl + `/transactions/changestatus/${transaction._id}?status=${state}`,
      transaction,
    );
  }
}
