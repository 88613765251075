import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IWorkWeekRequest } from 'src/app/models/workWeek.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-employee-row',
  templateUrl: './employee-row.component.html',
  styleUrl: './employee-row.component.scss',
})
export class EmployeeRowComponent {
  @Input() workWeek: IWorkWeekRequest;
  public acualWorkTime: string;
  public timeForm: FormGroup;

  constructor(
    public helperService: HelperService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.acualWorkTime = this.helperService.formatMinutes(this.workWeek.minutesWorked);

    this.timeForm = this.fb.group({
      timeInput: [this.acualWorkTime, [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3])(:[0-5][0-9])?$/)]],
    });

    this.getFormChanges();
  }

  private getFormChanges(): void {
    this.timeForm.get('timeInput').valueChanges.subscribe((value) => {
      const formattedHoursWorked = this.helperService.formatTimeInput(value);
      this.workWeek.minutesWorked = this.helperService.calculateMinutes(formattedHoursWorked);
    });
  }

  public getActualWorkTimeFromForm(): void {
    return this.timeForm.get('timeInput').value;
  }

  public getOvertime(weeklyHours: number): string {
    const weeklyHoursInMinuits = this.helperService.calculateMinutes(weeklyHours.toString());
    const minDifference = this.workWeek.minutesWorked - weeklyHoursInMinuits;

    return this.helperService.formatMinutes(minDifference);
  }
}
