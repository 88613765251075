import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { EClothingSize, EShoeSize } from 'src/app/enum/size.enum';
import { EProductType, IProduct } from 'src/app/models/product.model';

@Component({
  selector: 'app-shop-dialog',
  templateUrl: './shop-dialog.component.html',
  styleUrl: './shop-dialog.component.scss',
})
export class ShopDialogComponent {
  @Input() product: IProduct;

  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() acceptDialog: EventEmitter<any> = new EventEmitter();

  public showSizeDropdown = false;
  public dropDownIcon = faAngleDown;
  public EProductType = EProductType;
  public EClothingSize = EClothingSize;

  constructor(private toastrService: ToastrService) {}

  public getClothingSizes(): EClothingSize[] {
    const sizes = Object.values(EClothingSize).filter((value) => typeof value === 'string') as EClothingSize[];
    return Object.values(EClothingSize).filter((value) => typeof value === 'string') as EClothingSize[];
  }
  public getShoeSizes(): EShoeSize[] {
    return Object.values(EShoeSize).filter((value) => typeof value === 'string') as EShoeSize[];
  }

  public onSelectSize(size: EClothingSize | EShoeSize) {
    this.product.size = size;
    this.showSizeDropdown = false;
  }

  public onBuyProduct() {
    if (
      (this.product.type === EProductType.CLOTHING || this.product.type === EProductType.FOOTWEAR) &&
      !this.product.size
    ) {
      this.toastrService.error('Please select a size first');
      return;
    }

    this.acceptDialog.emit(this.product);
    this.closeDialog.emit();
  }

  public onCloseDialog() {
    this.closeDialog.emit();
  }

  public onToggleDropDown(): void {
    this.showSizeDropdown = !this.showSizeDropdown;
  }
}
