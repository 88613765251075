<button
  class="flex items-center h-[30px] p-2 rounded white gap-1 text-xs"
  [ngClass]="{
    'cursor-default': dataCategory,
    'cursor-pointer': !dataCategory,
    'bg-green-500 ': feedbackCategory === FeedbackCategory.SAVING,
    'bg-blue-500 ': feedbackCategory === FeedbackCategory.IMPROVEMENT,
    'bg-orange-500 ': feedbackCategory === FeedbackCategory.FEEDBACK,
    'bg-purple-500 ': feedbackCategory === FeedbackCategory.TOOLS,
    'bg-red-500 ': feedbackCategory === FeedbackCategory.OTHER,
    'isSelected': feedbackCategory === selectedCategory,
    'opacity-25': selectedCategory && feedbackCategory !== selectedCategory,
    'badgeButton': isHomePage
  }"
  (click)="onButtonClick(feedbackCategory)"
>
  <fa-icon [icon]="badgeIcon" style="color: #ffffff"></fa-icon>
  <span [title]="readableCategory" class="truncate"> {{ readableCategory }}</span>
</button>
