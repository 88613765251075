import { EClothingSize, EShoeSize } from '../enum/size.enum';

export interface IProduct {
  _id?: string;
  title: string;
  price: number;
  description: string;
  size?: EClothingSize | EShoeSize;
  type: EProductType;
  imgUrl: string;
  productDescription: string;
}

export enum EProductType {
  CLOTHING,
  FOOTWEAR,
  HARDWARE,
  VOUCHER,
  TIME,
  EQUIPMENT,
  OTHER,
}
