import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IProductIdea } from 'src/app/models/productIdea.model';
import { IdeaResponse } from 'src/app/models/response.model';
import { HelperService } from 'src/app/services/helper.service';
import { ProductIdeaService } from 'src/app/services/product-idea.service';

@Component({
  selector: 'app-product-ideas',
  templateUrl: './product-ideas.component.html',
  styleUrl: './product-ideas.component.scss',
})
export class ProductIdeasComponent {
  private subscription = new Subscription();

  public ideaDeadline = '12.03';
  public newIdea: IProductIdea;
  public productIdeas: IProductIdea[] = [];
  public oneDriveLink = '';

  constructor(
    private toastr: ToastrService,
    private productIdeaService: ProductIdeaService,
    private helperService: HelperService,
  ) {}

  public onCreateNewIdea(): void {
    if (!this.newIdea) {
      this.toastr.error('Text field is empty!');
    } else {
      this.createNewIdea();
    }
  }

  private createNewIdea(): void {
    this.subscription.add(
      this.productIdeaService.createIdea(this.newIdea, this.oneDriveLink).subscribe({
        next: (res: IdeaResponse) => {
          this.helperService.setIdeas([res.DATA, ...this.helperService.ideas$.getValue()]);
          this.newIdea = null;
          this.oneDriveLink = '';
          this.toastr.success('Product idea was successfully added');
        },
        error: () => {
          this.toastr.error('Failed to create a new product idea');
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
